import { Injectable } from '@angular/core';
import { ApiService, Resources } from '../services/api.service';
import { ApiSessionService } from '../services/api-session.service';
import { combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { MnbModelLoader, ModelAttribute, ModelChannel, ModelEntity, ModelMeasure } from '@shared-lib/modules/model/mnb-model.model';

@Injectable()
export class ApiModelLoader implements MnbModelLoader {

    constructor(private apiService: ApiService, private sessionService: ApiSessionService) {}

    getMeasures(): Promise<ModelMeasure[]> {
        return combineLatest([this.sessionService.sessionState$, this.apiService.query(Resources.MODEL_MEASURE)])
            .pipe(
                map(([session, measures]) => {
                    (session.portalInfo && session.portalInfo.portalSettings && session.portalInfo.portalSettings.measures || [])
                        .forEach(portalMeasureConfig => {
                            const modelMeasure = measures.find(m => m.code === portalMeasureConfig.code);
                            if (modelMeasure) {
                                modelMeasure.name = portalMeasureConfig.name || modelMeasure.name;
                            }
                        });

                    return measures;
                }),
                first()
            ).toPromise();
    }

    getAttributes(): Promise<ModelAttribute[]> {
        return combineLatest([this.sessionService.sessionState$, this.apiService.query(Resources.MODEL_ATTRIBUTE)])
            .pipe(
                map(([session, attributes]) => {
                    (session.portalInfo && session.portalInfo.portalSettings && session.portalInfo.portalSettings.attributes || [])
                        .forEach(portalAttributeConfig => {
                            const modelAttribute = attributes.find(m => m.code === portalAttributeConfig.code);
                            if (modelAttribute) {
                                modelAttribute.name = portalAttributeConfig.name || modelAttribute.name;
                            }
                        });

                    return attributes;
                }),
                first()
            ).toPromise();
    }

    getEntities(): Promise<ModelEntity[]> {
        return Promise.resolve([]);
    }

    getChannels(): Promise<ModelChannel[]> {
        return Promise.resolve([]);
    }

    getPlanNames(): Promise<Array<string>> {
        return new Promise((resolve, reject) => {

            // not implemented yet
            resolve([]);
        });
    }
}

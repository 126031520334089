import { Component, ElementRef, Host, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { MnbDropdownMenuComponent } from './mnb-dropdown-menu.component';

@Component({
    selector: 'mnb-dropdown-menu-button',
    template: `<ng-content></ng-content>`,
    styles: [':host { display: block; }'],
    host: { 'class': 'stretch-height' }
})

export class MnbDropdownMenuButtonComponent implements OnInit {

    public elementRefElement: HTMLElement;

    @Input() openedClass: string;

    constructor(
        @Host() private dropdownMenu: MnbDropdownMenuComponent,
        elementRef: ElementRef,
        private renderer: Renderer2) {
        this.elementRefElement = elementRef.nativeElement;
    }

    @HostListener('click') onInsideClick() {
        this.dropdownMenu.toggleDropdown();
    }

    ngOnInit(): void {
        this.dropdownMenu.registerButton(this);
    }

    onMenuToggleChange(value: boolean) {
        if (value) {
            this.renderer.addClass(this.elementRefElement, this.openedClass);
        } else {
            this.renderer.removeClass(this.elementRefElement, this.openedClass);
        }
    }

}

import { isNullOrUndefined } from 'util';
import { MnbUnitInfo } from '../../core/pipes/unit.pipe';

export class ChartData {
    public isTransparent ? = false;
    constructor(public label?: string, public value?: any, public secondLabel?: string, public color?: string) { }
}

export enum ChartDataType {
    primary = 'primary',
    secondary = 'secondary',
    breakdown = 'breakdown'
}

export enum ChartDataComparisonType {
    comparison = 'comparison',
    plan = 'plan'
}

export class ChartDataArray {

    public readonly isComparison = this.comparisonType === ChartDataComparisonType.comparison;
    public readonly isPlan = this.comparisonType === ChartDataComparisonType.plan;

    public containsCurrency: boolean = false;
    public isCurrencyBreakdown: boolean = false;

    constructor(public type: ChartDataType, public label: string, public data: Array<ChartData>, public comparisonType?: ChartDataComparisonType, public color?: string) { }

    public getData(limit?: number, inverted?: boolean): Array<ChartData> {
        return !isNullOrUndefined(limit) ? inverted ? this.data.slice(this.data.length - limit) : this.data.slice(0, limit) : this.data;
    }

}

export class ChartStyle {
    multiColor: boolean;
}

export class ChartValue {
    code: string;
    label: string;
    //TODO: Switch to unitInfo
    unit: string | MnbUnitInfo;
}

export class ChartConfig {
    data: Array<ChartDataArray> = [];

    value = new ChartValue();
    additionalValue = new ChartValue();

    seriesLabels: Array<string>;
    xAxisConfig = new XAxisConfig();
    yAxisConfig = new YAxisConfig();
    legendConfig = new LegendConfig();

    public getDataArray(type: ChartDataType, comparisonType: ChartDataComparisonType = null): ChartDataArray {
        return this.data.find(d => d.type === type && (comparisonType ? d.comparisonType === comparisonType : !d.isComparison));
    }

    public getDataArrays(type: ChartDataType, comparisonType: ChartDataComparisonType = null): Array<ChartDataArray> {
        return this.data.filter(d => d.type === type  && (comparisonType ? d.comparisonType === comparisonType : !d.isComparison));
    }

    customTooltip: TooltipConfig;

}

export class DonutChartConfig extends ChartConfig {
    label: string;
    style = new ChartStyle();
    paddingForLabels = 0;

    total: number;
    comparisonTotal: number;
}

export class BarChartConfig extends ChartConfig {
    rotateXLabels: boolean;
    tickPadding = 20;
    breakdownData: Array<ChartDataArray>;
    breakdownMode: string;
    additionalAxis: boolean;
    secondaryAsLine: boolean;
    limitXAxis?: boolean;
    showTooltips = true;
}

export class LineChartConfig extends ChartConfig {
    showChange: boolean;
    removeOuterSpacing: boolean;
    //TODO: This needs to be part of ChartDataArray (as child array)
    breakdownData: Array<ChartDataArray>;
    rotateXLabels: boolean;
    tickPadding = 20;
    breakdownMode: string;
    additionalAxis: boolean;
    showTooltips = true;
}

export class GaugeChartConfig extends ChartConfig {
    goalValue: number;
    hideGoalText: boolean;
    hideRangeText: boolean;
    noWrapInfoText: boolean;
}

export class XAxisConfig {
    hide: boolean;
}

export class YAxisConfig {
    hide: boolean;
}

export class LegendConfig {
    hide: boolean;
}

export class TooltipConfig {
    public header: string;
    public listContent: { labels: string[], data: { values: (number | [number, string])[] }[], unitCode?: string };
}

import { Directive, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MnbForm, MnbFormGroupHandler, MnbFormSectionHandler } from '../../model/mnb-form.model';

@Directive({
  selector: '[mnbFormSection]'
})
export class MnbFormSectionDirective implements OnInit, OnDestroy, MnbFormSectionHandler {

    private mnbForm: MnbForm;
    private groups = new Array<MnbFormGroupHandler>();
    private expander: MnbFormSectionExpander;

    constructor(
        @Optional() private formDirective: FormGroupDirective) {
    }

    ngOnInit(): void {
        if (this.formDirective && this.formDirective.form instanceof MnbForm) {
            this.mnbForm = this.formDirective.form;
            this.mnbForm.registerSection(this);
        }
    }

    ngOnDestroy(): void {
        if (this.mnbForm) {
            this.mnbForm.unregisterSection(this);
        }
    }

    public registerGroup(group: MnbFormGroupHandler) {
        this.groups.push(group);
    }

    public unregisterGroup(group: MnbFormGroupHandler) {
        this.groups = this.groups.filter((g) => g === group);
    }

    public registerExpander(expander: MnbFormSectionExpander) {
        this.expander = expander;
    }

    public unregisterExpander(group: MnbFormSectionExpander) {
        this.expander = null;
    }

    get hasErrorsToShow(): boolean {
        return this.groups.filter((group => group.hasErrorsToShow)).length > 0;
    }

    public refreshErrorState(): void {
        if (this.expander) {
            const hasErrors = this.hasErrorsToShow;
            this.expander.hasErrors = hasErrors;

            if (hasErrors) {
                this.expander.open();
            }
        }
    }
}

export interface MnbFormSectionExpander {
    hasErrors: boolean;
    open(): void;
}

import { Directive, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { MnbListActionHandlerDirective } from './mnb-list-action-handler.directive';

@Directive({
  selector: '[mnbListActionChild]'
})
export class MnbListActionChildDirective implements OnInit, OnDestroy {

    @Input('mnbListActionChild') index: number;
    @Input('mnbListActionChildValue') value: any;

    @HostBinding('class.hover') public isHovered = false;

    constructor(private handler: MnbListActionHandlerDirective) {}

    ngOnInit(): void {
        this.handler.register(this);
    }

    ngOnDestroy(): void {
        this.handler.unregister(this);
    }

    @HostListener('mousemove', ['$event'])
    onMouseOver() {
        const currentHoverIndex = this.handler.getHoveredElementIndex();
        const isElementHovered = currentHoverIndex !== -1;
        const isHovered = isElementHovered && this.handler.listElements[currentHoverIndex] === this;

        if (!isHovered) {
            if (isElementHovered) {
                this.handler.clearElementHover(currentHoverIndex);
            }
            this.isHovered = true;
        }
    }

    @HostListener('mouseout', ['$event'])
    onMouseOut() {
        this.isHovered = false;
    }

}

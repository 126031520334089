
import { Component, Input, ViewChild, ElementRef, Renderer2, AfterViewChecked, OnInit, Output, EventEmitter } from '@angular/core';
import { TimeComparisonFilter } from '@shared-lib/modules/core/services/time/time.model';
import { DateSpan } from '@shared-lib/modules/core/model/mnb-time.model';
import { DashboardWidgetData, DashboardWidgetDetail } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { DashboardWidgetTableModel, DashboardWidgetTableRowModel } from './mnb-dashboard-table-widget.model';
import { isNullOrUndefined } from 'util';
import { TimeFilterService } from '@shared-lib/modules/core/services/time/time-filter.service';
import { DashboardWidgetVisualizationSettingsMeasure } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { DashboardGridWidget } from '@shared-lib/modules/dashboards/util/mnb-dashboards-grid.util';
import { MnbChartTooltipData } from '@shared-lib/modules/charts/components/chart-tooltip-content.component';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { MnbUnitInfo } from '@shared-lib/modules/core/pipes/unit.pipe';

import { DrilldownEvent } from '@shared-lib/modules/dashboards/components/dashboard.models';
import { ModelAttribute, ModelMeasure } from '@shared-lib/modules/model/mnb-model.model';

@Component({
    selector: 'mnb-dashboard-table-widget',
    templateUrl: './mnb-dashboard-table-widget.component.html'
})
export class MnbDashboardTableWidgetComponent implements OnInit, AfterViewChecked {

    @Input() public gridWidget: DashboardGridWidget;
    @Input() public withoutHeader: boolean;
    @Input() public enableDrilldown = false;

    @ViewChild('tableElement', { static: false }) tableElement: ElementRef;

    public timeSpan: DateSpan;
    public comparisonTimeSpan: DateSpan;
    public hasComparisonFilter: boolean;
    public hasPlan: boolean;
    public planName: string;
    public measureSettings: Array<DashboardWidgetVisualizationSettingsMeasure>;
    public tableModel: DashboardWidgetTableModel;

    @Output() public drilldown: EventEmitter<DrilldownEvent> = new EventEmitter<DrilldownEvent>();

    constructor(
        private timeFilterService: TimeFilterService,

        ) {
    }

    ngOnInit() {
        const cachedDashboardSettings = this.gridWidget.dashboard.settings;

        if (cachedDashboardSettings) {
            const dashboardCurrencyCode = QueryFilter.getCurrencyCode(this.gridWidget.dashboard.settings.filters);
            const widgetCurrencyCode = QueryFilter.getCurrencyCode(this.gridWidget.widget.querySettings.filters) || dashboardCurrencyCode;

            const timeFilter = this.gridWidget.widget.querySettings.timeFilter ? this.gridWidget.widget.querySettings.timeFilter : cachedDashboardSettings.timeFilter;
            const comparisonFilter = this.gridWidget.widget.querySettings.timeFilter ? this.gridWidget.widget.querySettings.comparisonFilter : cachedDashboardSettings.comparisonFilter;
            this.hasComparisonFilter = !isNullOrUndefined(comparisonFilter);
            this.planName = (!isNullOrUndefined(this.gridWidget.widget.querySettings.plan) ? this.gridWidget.widget.querySettings.plan.name : null)
                || (!isNullOrUndefined(cachedDashboardSettings.plan) && !this.gridWidget.widget.querySettings.timeFilter ? cachedDashboardSettings.plan.name : null);
            this.hasPlan = !isNullOrUndefined(this.planName);

            this.timeSpan = this.timeFilterService.getTimePeriod(timeFilter);
            if (this.hasComparisonFilter) {
                this.comparisonTimeSpan = this.timeFilterService.getComparisonPeriod(timeFilter, <TimeComparisonFilter> comparisonFilter);
            }
            this.tableModel = new DashboardWidgetTableModel(
                this.hasComparisonFilter,
                this.hasPlan,
                this.gridWidget.widget.visualizationSettings.position.width,
                this.gridWidget.model.attributes,
                this.gridWidget.model.measures,
                this.gridWidget.widget.visualizationSettings.measures ? this.gridWidget.widget.visualizationSettings.measures : [],
                this.gridWidget.model.childAttributes);
            this.tableModel.headerRow = this.mapRow(this.gridWidget.data, this.gridWidget.model.measures, widgetCurrencyCode);

            this.tableModel.rows = this.gridWidget.data.rows.map(row => this.mapRow(row, this.gridWidget.model.measures, widgetCurrencyCode));

            // Preparing merged settings to use in template
            const dashboardSettings = this.gridWidget.dashboard.settings;
            const filters = this.gridWidget.widget.querySettings.filters ? this.gridWidget.widget.querySettings.filters : (dashboardSettings ? dashboardSettings.filters : null);
            this.hasComparisonFilter = !isNullOrUndefined(comparisonFilter);
            this.tableModel.mergedSettings = {
                comparisonFilter: comparisonFilter,
                filters: filters,
                timeFilter: timeFilter,
                measureCode: '', // Not required for merged settings
            };
        }
    }

    private mapRow(row: DashboardWidgetData, measures: ModelMeasure[], widgetCurrencyCode: string) {
        const rowModel = new DashboardWidgetTableRowModel();
        rowModel.attributes = row.attributes;
        rowModel.values = row.values;
        rowModel.comparisonValues = row.comparison && row.comparison['comparisonPeriod'] ? row.comparison['comparisonPeriod'].values : null;
        measures.forEach(measure => {
            rowModel.units[measure.code] = new MnbUnitInfo(measure.unit.code, measure.modifiers.isOriginalCurrency,
                measure.modifiers.isOriginalCurrency ? DashboardWidgetDetail.getCurrencyCode(row) || widgetCurrencyCode : null);
        });
        return rowModel;
    }

    ngAfterViewChecked(): void {

    }

    public initHeaderTooltipData(measure: ModelMeasure) {
        this.initTooltipData(measure, this.tableModel.headerRow, true);
    }

    public initCellTooltipData(measure: ModelMeasure, rowIndex: number, row: DashboardWidgetTableRowModel) {
        this.initTooltipData(measure, row, false);

    }

    public onDrilldown(row: DashboardWidgetTableRowModel, childAttribute: ModelAttribute): void {
        // TODO: Check custom dates vs relative
        // const timeFilter = this.gridWidget.getUsedTimeFilter();
        // const comparisonFilter = this.gridWidget.getUsedComparisonFilter();
        this.drilldown.next({
            // todo!
            row: {
                ...row,
                fromDate: null,
                toDate: null,
                comparisonFromDate: null,
                comparisonToDate: null,
            },
            attribute: childAttribute
        });
    }

    private initTooltipData(measure: ModelMeasure, row: DashboardWidgetTableRowModel, forHeader: boolean) {
        if (row.tooltipData[measure.code]) {
            return;
        }
        const tooltipData = new MnbChartTooltipData();
        tooltipData.values = {
            unitCode: measure.unit.code,
            unit: row.units[measure.code]
        };

        tooltipData.withSecondHeaderLabel = this.tableModel.attributes.length > 1;

        tooltipData.withComparison = this.tableModel.hasComparisonFilter;
        tooltipData.plan = this.tableModel.hasPlan ? this.planName : null;

        if (forHeader) {
            tooltipData.headerData = {
                label: this.tableModel.attributes.length > 0 ? this.tableModel.attributes[0].attribute.name : null,
                label2: this.tableModel.attributes.length > 1 ? this.tableModel.attributes[1].attribute.name : null
            };
        } else {
            tooltipData.headerData = {
                label: this.tableModel.attributes.length > 0 ? row.attributes[this.tableModel.attributes[0].attribute.code] : null,
                label2: this.tableModel.attributes.length > 1 ? row.attributes[this.tableModel.attributes[1].attribute.code] : null
            };
        }
        tooltipData.data = {
            label: measure.name,
            value: row.values[measure.code],
            comparisonValue: this.tableModel.hasComparisonFilter ? row.comparisonValues[measure.code] : null,
            planValue: this.tableModel.hasPlan && measure.planMeasure ? row.values[measure.planMeasure.code] : null
        };

        row.tooltipData[measure.code] = tooltipData;

    }

}

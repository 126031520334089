import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[ngVar]',
})
export class NgVarDirective {

    context: any = {};
    embeddedView: EmbeddedViewRef<any>;

    @Input() set ngVar(context: any) {
        this.context.$implicit = this.context.ngVar = context;
        this.updateView();
    }

    constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>) { }

    updateView() {
        if (this.embeddedView) {
            this.embeddedView.destroy();
        }
        this.embeddedView = this.vcRef.createEmbeddedView(this.templateRef, this.context);
    }

}
import { MnbBusyStatus } from '@shared-lib/modules/core/model/mnb-core-busy-status.model';
import { BehaviorSubject, Subject } from 'rxjs';
import {
    DrilldownPath,
    EntityDrilldownElement,
    ReportEntityDrilldownRowData,
    ReportSettingsEntityDrilldown,
    ReportSettingsEntityDrilldownEntity,
    ReportSettingsDrilldownStep, ReportRankComparisonDataRow
} from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { QueryFilter, QuerySettingsSort } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { ModelAttribute, ModelMeasure } from '@shared-lib/modules/model/mnb-model.model';

export type MnbReportsEntityDrilldownDisplayModel = {
    hasComparison: boolean;
    measures: MnbReportsEntityDrilldownDisplayMeasureModel[];
    hasMoreMeasures: boolean;
    showsFirstMeasure: boolean;
    showsLastMeasure: boolean;
    drilldowns: MnbReportsEntityDrilldownDisplayDrilldownModel[];
};

export type MnbReportsEntityDrilldownDisplayMeasureModel = {
    measure: ModelMeasure;
    value: number;
    comparisonValue: number;
    hasNegativeComparison: boolean;
    hidden?: boolean;
};

export type MnbReportsEntityDrilldownDisplayDrilldownModel = {
    name: string;
    pathRef?: string;
    keyAttributes: ModelAttribute[];
    nameAttributes: ModelAttribute[];
    measures: MnbReportsEntityDrilldownDisplayDrilldownMeasureModel[];
    hasMoreMeasures: boolean;
    showsFirstMeasure: boolean;
    showsLastMeasure: boolean;
    expanded: boolean;
    load: MnbBusyStatus;
    sort$: Subject<QuerySettingsSort>;
    data$: BehaviorSubject<MnbReportsEntityDrilldownDisplayDrilldownDataModel>;
    hidden?: boolean;
};

export type MnbReportsEntityDrilldownDisplayDrilldownMeasureModel = {
    measure: ModelMeasure;
    hidden: boolean;
    isLastVisible?: boolean;
    sortDirection: string|null;
};

export type MnbReportsEntityDrilldownDisplayDrilldownDataModel = {
    restricted?: boolean;
    rows?: MnbReportsEntityDrilldownRow[];
};

export type MnbReportsEntityDrilldownRow = {
    link: MnbReportsEntityDrilldownDisplayDrilldownLinkModel
    data: ReportEntityDrilldownRowData
};

export type MnbReportsEntityDrilldownDisplayDrilldownLinkModel = {
    selectedPathIdentifier?: string;
    selectedElementName?: string;

    keyValues: {[code: string]: string};
    // Legacy selectors
    viewIndex?: number;
    entity?: ReportSettingsEntityDrilldownEntity;
};

export class MnbReportsEntityDrilldownDisplayUtil {

    public static getCurrentPathElement(
        drilldownSettings: ReportSettingsEntityDrilldown,
        pathIdentifier?: string, elementName?: string
    ): { element: EntityDrilldownElement | null; isLast: boolean } {
        const _pathIdentifier = pathIdentifier || drilldownSettings.selectedPathIdentifier;
        const _elementName = elementName || drilldownSettings.selectedElementName;

        let isLast = false;
        const elements = drilldownSettings.drilldownPathList
            .filter(path => path.pathIdentifier === _pathIdentifier)
            .map(path => path.path)
            .reduce((acc, val) => {
                const newAcc = acc.concat(val);
                if (newAcc.length >  0 && newAcc[newAcc.length -  1].elementName === _elementName) {
                    isLast = true;
                }
                return newAcc;
            }, []);

        const selected = elements.find(element => element.elementName === _elementName);
        return { element: selected || null, isLast };
    }

    public static buildEntityLink(drilldownSettings: ReportSettingsEntityDrilldown, row: ReportEntityDrilldownRowData): MnbReportsEntityDrilldownDisplayDrilldownLinkModel {

        const currentElementSelection = this.getCurrentPathElement(drilldownSettings);
        // return null if it's the last element of the path and already part of the filters
        const partOfDrilldownFilters = !!drilldownSettings.selectedDrilldownFilters
            ? drilldownSettings.selectedDrilldownFilters.find(filter => {
                return currentElementSelection.element.keyAttributes.map(k => k.code).includes(filter.attributeCode);
            })
            : false;
        if (currentElementSelection.isLast && partOfDrilldownFilters) {
            return null;
        }

        const keyValues: { [code: string]: string } = {};

        for (const attribute of currentElementSelection.element.keyAttributes) {
            if (!!row.attributes[attribute.code]) {
                keyValues[attribute.code] = row.attributes[attribute.code];
            } else {
                // Covering null value to be filterable as well
                keyValues[attribute.code] = '';
            }
        }
        return {
            selectedElementName: drilldownSettings.selectedElementName,
            selectedPathIdentifier: drilldownSettings.selectedPathIdentifier,
            keyValues: keyValues
        };
    }

}

// Build drilldown entity link for the current row
export function buildEntityLink(path: DrilldownPath, row: ReportRankComparisonDataRow): MnbReportsEntityDrilldownDisplayDrilldownLinkModel {
    if (!path || !row || !row.attributes) {
        return null;
    }
    const attrKey = Object.keys(row.attributes)[0];
    const currElemIndex = path.path.findIndex(element => element.keyAttributes[0].code === attrKey);
    const currentElementSelection = path.path[currElemIndex];
    if (currElemIndex === path.path.length - 1) {
        return null;
    }
    const keyValue = row.attributes;
    if (currElemIndex === -1) {
        const firstElement = path.path[0];
        return {
            selectedElementName: firstElement.elementName,
            selectedPathIdentifier: path.pathIdentifier,
            keyValues: keyValue,
        };

    }
    return {
        selectedElementName: currentElementSelection.elementName,
        selectedPathIdentifier: path.pathIdentifier,
        keyValues: keyValue,
    };
}

export interface MnbReportDrilldownStepModel {
    attributeCode?: string;
    entity: ReportSettingsEntityDrilldownEntity;
    step: ReportSettingsDrilldownStep;
    attributes: {[code: string]: string};
}

export type DrilldownFilterContext = {
    filter: QueryFilter;
    nameAttributeValues: string[];
    keyAttributeValues: string[];
};


export function mapToDrilldownContext(filters: QueryFilter[], keyValues: {[code: string]: string}, nameValues: {[code: string]: string}): DrilldownFilterContext[] {

    return filters.map(filter => {
        const result: DrilldownFilterContext = {
            filter: filter,
            keyAttributeValues: Object.keys(keyValues).map(key => keyValues[key]),
            nameAttributeValues: Object.keys(nameValues).map(key => nameValues[key]),
        };

        return result;
    });

}

import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import deepEqual from 'deep-equal';

export class MnbForm extends FormGroup {

    private sections: Array<MnbFormSectionHandler> = [];

    static hasErrors(control: AbstractControl) {
        return control && control.invalid && control.dirty && !control.pristine;
    }

    static noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        return isWhitespace && control.value ? { 'noSpace': true } : null;
    }

    static isPasswordMatch(otherControl: FormControl) {
        return (control: FormControl) => {

            const ownValue = control.value;
            const otherValue = otherControl.value;

            const isSame = deepEqual(ownValue, otherValue);

            return !isSame && ownValue ? { 'passwordMatch': true } : null;
        };
    }


    public static isValid(formElem: AbstractControl): boolean {
        if (formElem instanceof FormControl) {
            return formElem.valid;
        }

        if (formElem instanceof FormGroup || formElem instanceof FormArray) {
            let isValid = true;
            Object.values(formElem.controls).forEach(control => {
                isValid = isValid && MnbForm.isValid(control);
            });
            return isValid;
        }
        return true;
    }

    public static markFormAsDirtyAndUnread(formElem: AbstractControl) {
        if (formElem instanceof FormControl) {
            MnbForm.markControlTouchedAndDirty(formElem);
            return;
        }

        if (formElem instanceof FormArray) {
            MnbForm.markFormGroupTouchedAndDirty(formElem);
        }

        if (formElem instanceof FormGroup) {
            MnbForm.markFormGroupTouchedAndDirty(formElem);
        }
    }


    private static markFormGroupTouchedAndDirty(formGroup: FormGroup|FormArray) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            MnbForm.markControlTouchedAndDirty(control);

            if (control.controls) {
                MnbForm.markFormAsDirtyAndUnread(control);
            }
        });
    }

    private static markControlTouchedAndDirty(control: FormControl): void {
        control.markAsDirty();
        control.markAsTouched();
        control.updateValueAndValidity();
    }

    public registerSection(section: MnbFormSectionHandler) {
        this.sections.push(section);
    }

    public unregisterSection(section: MnbFormSectionHandler) {
        this.sections = this.sections.filter((s) => s === section);
    }

    public refreshErrorState(): void {
        this.sections.forEach((section) => {
            section.refreshErrorState();
        });
    }

}

export interface MnbFormSectionHandler {
    refreshErrorState(): void;
}

export interface MnbFormGroupHandler {

    hasErrorsToShow: boolean;
}

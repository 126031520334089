import { Type } from '@angular/core';

export interface MnbModelConfig {
    loader: Type<MnbModelLoader>;
}

export interface MnbModelLoader {

    getMeasures(): Promise<ModelMeasure[]>;

    getAttributes(): Promise<ModelAttribute[]>;

    getEntities(): Promise<ModelEntity[]>;

    getChannels(): Promise<ModelChannel[]>;

    getPlanNames(): Promise<string[]>;

}


export class ModelDateReferences {
    od: boolean;
    id: boolean;
    rd: boolean;
}

export class ModelDimension {
    code: string;
    name: string;
    description: string;
}

export class ModelField {
    code: string;
    name: string;
    isHidden: boolean;
    isUnavailable?: boolean;
    tags?: string[];
}

export enum ModelFieldType {
    MEASURE = 'measure',
    ATTRIBUTE = 'attribute'
}

export class ModelCalculation {
    calculation: string;
    parts: Array<{ code: string, name: string, constantValue?: string } | ModelCalculation>;
}

export enum ModelAttributeCode {
    PRD_NAME = 'prdName'
}

export class ModelAttribute extends ModelField {
    shortName?: string;
    isSupportingTimeHistogram?: boolean;
    drillupAttrCode?: string;
    detailAttrCode?: string;
    hierarchies: Array<ModelAttributeHierarchy>;
    isDirect?: boolean;
    context?: ModelAttributeContext;
    // TODO: Type
    dataLevels?: any;
    dimension?: ModelDimension;
    colors?: {
        value: string,
        color: string,
        shade: number
    }[];
    isImageUrl?: boolean;
    isMultiValueField?: boolean;

    public static isCurrency(code: string) {
        return code === 'saleOrdOriginalCurrency';
    }
}

export enum ModelMeasureCode {
    OMS_IVT_QTY = 'omsIvtQty'
}

export class ModelMeasure extends ModelField  {
    unit?: ModelMeasureUnit;
    interpretation?: number;
    measureType?: ModelMeasureType;
    planMeasure?: ModelMeasure;
    assistMeasureCodes?: string[];

    aggregator?: any;
    dataLevel?: { code: string, name: string };
    modifiers?: ModelMeasureModifiers;
}

export class ModelMeasureDetailed extends ModelMeasure {
    calculations: Array<ModelCalculation>;
    dateReferences?: ModelDateReferences;
    description?: string;
    detailDescription?: string;
    metricMeasures?: Array<{ code: string, name: string, dateReferences: ModelDateReferences }>;
}

export class ModelMeasureType {
    code: string;
    isAverage?: boolean;
    isRate?: boolean;
}

export class ModelData {
    code: string;
    label: string;
}
export class ModelMeasureUnit {
    code: string;
    name?: string;
}
export class ModelMeasureModifiers {
    isOriginalCurrency: boolean;
    isRealtime: boolean;
}
export class ModelAttributeHierarchy extends ModelField {
    code: string;
    childLevels: Array<string>;
    parentLevel: string;
}

export class ModelAttributeContext {
    code: string;
}

export class ModelEntity {
    id: number;
    code: string;
    typeCode: string;
    name: string;

    keyAttributes: {code: string}[];
    nameAttributes: {code: string}[];
    displayAttributes: {code: string, isAdditional: boolean}[];
    displayMeasures: {code: string}[];
    displayMeasureGroups: {
        code: string;
        name: string;
        measures: {code: string}[];
    }[];
    imageAttribute: {code: string};

    supportsTimeFilter: boolean;

    children: ModelEntity[];
}

export class ModelChannel {
    level: number;

    level1Value: string;
    level2Value: string;

    typeCode: string;
    marketplaceCode: string;
    imageUrl: string;
}
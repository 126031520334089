import { Component, Input, Pipe, PipeTransform } from '@angular/core';
import { MnbQuickTimeFilterValue } from '@shared-lib/modules/filters/components/quick-time-filter/mnb-quick-time-filter.component';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { TimeFilterService } from '@shared-lib/modules/core/services/time/time-filter.service';
import { MnbModelService } from '@shared-lib/modules/model/services/mnb-model.service';
import { TimeComparisonFilter } from '@shared-lib/modules/core/services/time/time.model';
import { filterTypeOptions } from '@shared-lib/modules/queries/model/query-filter.model';
import { TranslateService } from '@ngx-translate/core';


@Component({
    templateUrl: './mnb-quick-filter-overview.component.html',
    selector: 'mnb-quick-filter-overview',
    exportAs: 'filterOverview'
})
export class MnbQuickFilterOverviewComponent {

    @Input() filters: QueryFilter[] = [];
    @Input() timeFilter: MnbQuickTimeFilterValue;

    public filtersVisible = false;

    public toggleFilters(): void {
        this.filtersVisible = !this.filtersVisible;
    }
}

@Pipe({
    name: 'overviewFilterLabel'
})
export class OverviewFilterLabelPipe implements PipeTransform {

    constructor(private modelService: MnbModelService, private translate: TranslateService) {
    }

    async transform(filter: QueryFilter, ...args: any[]): Promise<any> {
        const attribute = await this.modelService.getAttribute(filter.attributeCode);
        const filterLabelCode = filterTypeOptions.find(opt => filter.typeCode === opt.code).label;

        return `<i>${attribute.name}</i> ${this.translate.instant(filterLabelCode)}: ${filter.values.join(', ')}`;
    }
}

@Pipe({
    name: 'overviewTimeFilterLabel'
})
export class OverviewTimeFilterLabelPipe implements PipeTransform {

    constructor(private timeFilterService: TimeFilterService) {
    }

    async transform(filter: MnbQuickTimeFilterValue, opts?: OverViewTimeFilterOptions): Promise<any> {
        const options = opts || {};
        if (options.comparisonLabel) {
            return await this.timeFilterService.getComparisonLabelWithDateSpan(
                filter.comparisonFilter as TimeComparisonFilter, {...filter.timeFilter}
            );
        }

        return await this.timeFilterService.getTimeLabelWithDateSpan({
            ...filter.timeFilter
        });
    }
}

type OverViewTimeFilterOptions = {
    comparisonLabel?: boolean;
};

import { Directive, DoCheck, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { MnbFormSectionDirective, MnbFormSectionExpander } from '../../../../../../../shared-lib/src/lib/modules/core/directives/form-section/mnb-form-section.directive';
import { isNullOrUndefined } from 'util';

@Directive({
  selector: '[mnbExpander]'
})
export class MnbExpanderDirective implements OnInit, DoCheck, OnDestroy, MnbFormSectionExpander {

  
  static expanders: Array<MnbExpanderDirective> = [];
  
  @Output('mnbExpander') onChange: EventEmitter<any> = new EventEmitter<any>();
  
  private _value: string;
  public expanded: boolean;
  private _hasErrors: boolean;
  public element: HTMLElement;
  
  @Output() valueChange = new EventEmitter();
  @Input() expanderCode: string;
  @Input() disabled: boolean;
  
  constructor(elementRef: ElementRef, 
    @Optional() private formSection: MnbFormSectionDirective) {

    this.element = elementRef.nativeElement;

    if (this.formSection) {
      this.formSection.registerExpander(this);
    }
  }

  @Input()
  get value() {
    return this._value;
  }
  
  set value(value) {
    this._value = value;
    this.valueChange.emit(this._value);
  }

  @HostBinding('class.has-error')
  get hasErrors(): boolean {
    return this._hasErrors;
  }
  
  set hasErrors(val: boolean) {
    this._hasErrors = val;
  }

  public open(): void {
    if (this.isExpanderCodeDefined()) {
      this.value = this.expanderCode;
    } else {
      this.expanded = true;
      this.refreshExpandedClass();
    }
  }

  ngOnInit() {
    this.expanded = this.element.classList.contains('expanded');
    this.onChange.emit(this.expanded);
    MnbExpanderDirective.expanders.push(this);
  }

  ngOnDestroy() {
    MnbExpanderDirective.expanders = MnbExpanderDirective.expanders.filter((expander) => expander.expanderCode === this.expanderCode);

    if (this.formSection) {
      this.formSection.unregisterExpander(this);
    }
  }

  @HostListener('click', ['$event.target'])
  onClick(element: HTMLElement) {
    if (this.disabled) {
      return;
    }
    if (!element.classList.value.includes('icon-')) {
      this.expanderAction();
    }
  }

  ngDoCheck(): void {
    if (this.isExpanderCodeDefined()) {
      this.expanded = this.expanderCode === this.value;
      this.refreshExpandedClass();
    }
  }

  expanderAction() {
    if (this.isExpanderCodeDefined()) {
      this.value = this.value !== this.expanderCode ? this.expanderCode : null;
    } else {
      this.expanded = !this.element.classList.contains('expanded');
      this.refreshExpandedClass();
    }
  }

  private isExpanderCodeDefined(): boolean {
    return !isNullOrUndefined(this.expanderCode);
  }

  private refreshExpandedClass() {
    const wasExpanded = this.element.classList.contains('expanded');
    let changed = false;
    if (!wasExpanded && this.expanded) {
      this.element.classList.add('expanded');
      changed = true;
      
    } else if (wasExpanded && !this.expanded) {
      this.element.classList.remove('expanded');
      changed = true;
      
    }
    if (changed && this.onChange) {
      this.onChange.emit(this.expanded);
    }
  }

}

import { getReportType, ReportBaseFields, ReportExperienceGroup, ReportSettings, ReportSettingsEntityDrilldown, ReportTypeCode } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import deepEqual from 'deep-equal';

/**
 * Cleans up any filters where the value is an empty array
 */
export function cleanEmptyFilters(settings?: ReportSettings): (ReportSettings | null) {
    if (settings == null) {
        return null;
    }
    const result = {...settings};
    const reportType = getReportType(result);
    const resultSetting = result[reportType] as ReportBaseFields;
    resultSetting.filters = resultSetting.filters.filter(filter => filter.values.length > 0);
    return result;
}

/**
 * @returns null if `settings[*].filters` is an empty array, otherwise the parameter `settings`
 */
export function nullIfNothingSet(settings?: ReportSettings): (ReportSettings | null) {
    const reportType = getReportType(settings);
    const containsOnlyEmptyFilterArray = Object.keys(settings[reportType]).length === 1 && settings[reportType].filters && settings[reportType].filters.length === 0;
    return containsOnlyEmptyFilterArray ? null : settings;
}



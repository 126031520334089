import { NgZone } from '@angular/core';
import { BarChartConfig, ChartData, ChartDataArray, ChartDataType } from '../services/chart.models';

import { MnbUnitPipe } from '@shared-lib/modules/core/pipes/unit.pipe';
import { MnbEmptyValuePipe } from '@shared-lib/modules/core/pipes/empty-value.pipe';
import { MnbUnitService } from '../../core/services/unit/mnb-unit.service';
import { BandAxisRenderer, LegendRenderer, LinearAxisRenderer, Rectangle, TooltipRenderer } from './chart-renderer';
import * as _d3 from 'd3';
import { ScaleBand, ScaleLinear, Selection } from 'd3';
import { ColumnBarChartAttrHandler, ColumnBarChartRenderer, ColumnBarRenderer, ParallelColumnBarRenderer, StackedColumnBarRenderer } from './column-bar-chart-renderer';
import { isNullOrUndefined } from 'util';


export class BarChartRenderer extends ColumnBarChartRenderer {

  constructor(
    protected config: BarChartConfig,
    zone: NgZone,
    unitPipe: MnbUnitPipe,
    emptyPipe: MnbEmptyValuePipe,
    unitService: MnbUnitService
  ) {
    super(config, zone, unitPipe, emptyPipe, unitService);
  }

  async render(el: HTMLElement | HTMLElement[]) {

    // change this later when mnb-chart passes in ele[0] instead of ele
    const temp: any = el;
    const ele: HTMLElement = temp.length ? temp[0] : temp;
    this.ele = ele;

    this.svgElement = this.createSvgElement(ele);
    this.svgHTMLElement = this.svgElement.node();


    const labelPaddingLeft = this.config.xAxisConfig.hide ? 0 : 15;
    const labelPaddingRight = this.config.xAxisConfig.hide ? 0 : (this.rendererConfig.hasSecondaryAxis() ? 20 : 10);

    const dimensions: { height: number, width: number } = await super.getDimensions(ele);

    let legendHeight = 0;
    if (!this.config.legendConfig.hide) {
      legendHeight = new LegendRenderer(this.config, this.emptyPipe).render(this.svgElement, this.el, this.config.data, dimensions.width - 1);
    }

    const chartRect: Rectangle = {
      x: 0,
      y: legendHeight,
      width: dimensions.width - labelPaddingRight,
      height: dimensions.height - legendHeight
    };

    this.gElement = this.svgElement.append('g');

    const yAxisRenderer = new BandAxisRenderer(this.gElement, this.config, true, this.emptyPipe);
    const xAxisRenderer = new LinearAxisRenderer(this.gElement, this.config, false, this.rendererConfig.hasSecondaryAxis(), this.unitPipe);

    const xAxisHeight = xAxisRenderer.calcAxisHeight();
    let secondaryXAxisHeight = 0;

    const secondaryXAxisRenderer =  new LinearAxisRenderer(this.gElement, this.config, true, this.rendererConfig.hasSecondaryAxis(), this.unitPipe);
    if (this.rendererConfig.hasSecondaryAxis()) {
      secondaryXAxisHeight = secondaryXAxisRenderer.calcAxisHeight();
    }

    const innerHeight = chartRect.height - xAxisHeight - secondaryXAxisHeight;

    const dataLimit = yAxisRenderer.calcMaximumBands(innerHeight, this.rendererConfig);

    const yAxis = yAxisRenderer.renderY(innerHeight, chartRect.width, dataLimit);

    const innerRect: Rectangle = {
      x: chartRect.x + Math.max(yAxis.width, labelPaddingLeft),
      y: chartRect.y + secondaryXAxisHeight,
      width: chartRect.width - Math.max(yAxis.width, labelPaddingLeft),
      height: innerHeight
    };

    this.gElement.attr('transform', `translate(${innerRect.x},${innerRect.y})`);

    const xAxis = xAxisRenderer.renderX(innerRect.width, innerRect.height, dataLimit, true);
    let secondaryXAxis: {scale: ScaleLinear<number, number>};
    if (this.rendererConfig.hasSecondaryAxis()) {
      secondaryXAxis = secondaryXAxisRenderer.renderX(innerRect.width, innerRect.height, dataLimit, true);
    }

    const bars: BarRenderer[] = [];

    // comparison series
    if (this.rendererConfig.hasComparisonData()) {
      // primary
      bars.push(new BarRenderer(this.gElement, this.rendererConfig.getComparisonData(), this.rendererConfig.hasComparisonData(), this.rendererConfig.hasSecondaryData()));
      if (this.rendererConfig.hasSecondaryData()) {
        bars.push(new BarRenderer(this.gElement, this.rendererConfig.getSecondaryComparisonData(), this.rendererConfig.hasComparisonData(), this.rendererConfig.hasSecondaryData(), null, this.rendererConfig.hasSecondaryAxis()));
      }
    }

    if (this.config.breakdownMode) {
      // render with breakdown data. breakdown doesn't render any comparison
      switch (this.config.breakdownMode) {
        case 'stacked':
          const stackedRenderer = new StackedBarRenderer(this.gElement, false);
          stackedRenderer.render(this.config.getDataArrays(ChartDataType.breakdown), xAxis.scale, yAxis.scale, dataLimit);
          break;
        case 'fill-stacked':
          const fillStackedRenderer = new StackedBarRenderer(this.gElement, true);
          fillStackedRenderer.render(this.config.getDataArrays(ChartDataType.breakdown), xAxis.scale, yAxis.scale, dataLimit);
          break;
        case 'parallel':
          const parallelRenderer = new ParallelBarRenderer(this.gElement);
          parallelRenderer.render(this.config.data, xAxis.scale, yAxis.scale);

          break;
        default:
          console.error('breakdownData without a configured breakdownMode');
      }


    } else {
      // primary
      bars.push(new BarRenderer(this.gElement, this.rendererConfig.getPrimaryData(), this.rendererConfig.hasComparisonData(), this.rendererConfig.hasSecondaryData()));
      if (this.rendererConfig.hasSecondaryData()) {
        bars.push(new BarRenderer(this.gElement, this.rendererConfig.getSecondaryData(), this.rendererConfig.hasComparisonData(), this.rendererConfig.hasSecondaryData(), null, this.rendererConfig.hasSecondaryAxis()));
      }

      if (this.rendererConfig.hasPlanData()) {
        bars.push(new BarRenderer(this.gElement, this.rendererConfig.getPrimaryPlanData(), this.rendererConfig.hasComparisonData(), this.rendererConfig.hasSecondaryData()));
      }
      if (this.rendererConfig.hasSecondaryData() && this.rendererConfig.getSecondaryPlanData()) {
        bars.push(new BarRenderer(this.gElement, this.rendererConfig.getSecondaryPlanData(), this.rendererConfig.hasComparisonData(), this.rendererConfig.hasSecondaryData(), null, this.rendererConfig.hasSecondaryAxis()));
      }
    }

    bars.forEach(bar => bar.render((!bar.secondaryAxis ? xAxis.scale : secondaryXAxis.scale), yAxis.scale, dataLimit));

    new TooltipRenderer(this.config, this.tooltipContent, bars).render(this.svgElement, ele, innerRect, yAxis.scale, xAxis.scale, dataLimit, true, true);

  }

}

export class BarRenderer extends ColumnBarRenderer {

  constructor(
    gElement: Selection<SVGGElement, {}, null, undefined>,
    private data: ChartDataArray,
    hasComparison: boolean,
    private hasSecondaryData: boolean,
    private index?: number,
    public secondaryAxis?: boolean,
    public additionalAxisPadding?: number) {

    super(gElement, data, hasComparison);

  }

  public render(x: ScaleLinear<number, number>, y: ScaleBand<string>, limit?: number) {
    const padding: number = this.hasComparison && !this.data.isComparison ? 12 : 8;

    const barBaseHeight: number = this.hasSecondaryData ? y.bandwidth() / 2 : y.bandwidth();

    this.additionalAxisPadding = 0;

    // TODO: Fix this code (see column charts)

    const zeroX = x(0);
    // primary and not comparison case
    const handlers: ColumnBarChartAttrHandler = {
      x: (d: ChartData): number => Math.min(x(d.value), zeroX),
      y: (d: ChartData): number => y(d.label) + padding / 2 + (this.secondaryAxis ? this.additionalAxisPadding : 0),
      height: (d: ChartData) => barBaseHeight - padding,
      width: (d: ChartData) => Math.abs(zeroX - x(d.value)),
      class: () => this.className
    };

    if (this.data.type === ChartDataType.primary && this.data.isComparison) {
      handlers.y = (d: ChartData): number => y(d.label) + (padding / 1.5) / 2 + (this.secondaryAxis ? this.additionalAxisPadding : 0);
      handlers.height = (d: ChartData) => barBaseHeight - (padding / 1.5);
    } else if (ChartDataType.secondary === this.data.type && this.data.isComparison) {
      handlers.y = (d: ChartData): number => barBaseHeight + y(d.label) + (padding / 1.5) / 2 + (this.secondaryAxis ? this.additionalAxisPadding : 0);
      handlers.height = (): number => barBaseHeight - padding / 1.5;
    } else if (ChartDataType.primary === this.data.type && this.data.isPlan ) {
      handlers.width = () => 2;
      handlers.height = () => y.bandwidth();
      handlers.y = (d: ChartData) => y(d.label);
      handlers.x = (d: ChartData) => x(d.value) - 1;
      if (this.hasSecondaryData) {
        handlers.height = () => y.bandwidth() / 2;
      }
    } else if (ChartDataType.secondary === this.data.type && this.data.isPlan ) {
      handlers.width = () => 2;
      handlers.y = (d: ChartData) => y(d.label) + y.bandwidth() / 2;
      handlers.height = () => y.bandwidth() / 2;
      handlers.x = (d: ChartData) => x(d.value) - 1;
    } else if (ChartDataType.secondary === this.data.type && !this.data.isComparison) {
      handlers.y = (d: ChartData): number => barBaseHeight + y(d.label) + padding / 2 + (this.secondaryAxis ? this.additionalAxisPadding : 0);
    }

    super.renderInternal(this.data, handlers, limit, true);
  }

  public switchHighlight(index: number, highlight: boolean) {

  }

}

export class StackedBarRenderer extends StackedColumnBarRenderer {

  constructor(gElement: Selection<SVGGElement, {}, null, undefined>, filled?: boolean) {
    super(gElement, filled);
  }

  public render(arrays: Array<ChartDataArray>, x: _d3.ScaleLinear<number, number>, y: _d3.ScaleBand<string>, limit?: number) {

    const handlers: ColumnBarChartAttrHandler = {
      x: (d: ChartData) => x(d[0]),
      y: (d: any) => y(<string><unknown>d.data.label) + this.PADDING / 2,
      height: (d: ChartData) => y.bandwidth() - this.PADDING,
      width: (d: ChartData) => x(!isNaN(d[1]) ? d[1] : 0) - x(d[0])
    };
    super.renderInternal(arrays, handlers, limit, true);
  }

  public switchHighlight(index: number, highlight: boolean) {

  }
}

export class ParallelBarRenderer extends ParallelColumnBarRenderer {

  constructor(
    gElement: Selection<SVGGElement, {}, null, undefined>
    ) {
    super(gElement);
  }


  public render(arrays: Array<ChartDataArray>, x: ScaleLinear<number, number>, y: ScaleBand<string>) {
    const breakdownData = arrays.filter(dataArray => dataArray.type === ChartDataType.breakdown);
    // build breakdownSeries, group by key
    const breakdownSeries = super.createBreakdownSeriesParallel(breakdownData).map((arr) => {
      return arr.filter(d => !isNullOrUndefined(y(d.label)));
    });

    // scale for breakdown groups
    const yBreakdown: ScaleBand<number> = d3.scaleBand<number>()
      .domain(d3.range(breakdownSeries.length))
      .range([5, y.bandwidth() - 5]);

    const zeroX = x(0);

    const handlers: ColumnBarChartAttrHandler = {
      x: (d) => Math.min(x(d.value), zeroX),
      y: (d, i) => y(d.label),
      width: (d) => Math.abs(zeroX - x(d.value)),
      height: () => yBreakdown.bandwidth(),
      transform: (d, i) =>  `translate( 0, ${yBreakdown(i)} )`
    };

    super.renderInternal(breakdownSeries, handlers);
  }
}

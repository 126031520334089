import { ChangeDetectorRef, Component, NgZone } from '@angular/core';

@Component({
    selector: 'scrolling-handler',
    templateUrl: './mnb-scrolling-handler.component.html',
})
export class MnbScrollingHandlerComponent {
    private readonly SPEED_SLOW = 20;
    private readonly SPEED_FAST = 50;

    public scrollAreaTop = false;
    public scrollAreaBot = false;
    public scrollAreaMid = false;

    private delay = 45;
    private speed = this.SPEED_SLOW;

    private scrollingHandler;

    constructor(private zone: NgZone, private cdr: ChangeDetectorRef) {
        this.zone = zone;
        this.cdr = cdr;
    }

    public startScroll(direction: string) {
        this.setSpeed('slow');
        clearInterval(this.scrollingHandler);
        this.showScrollAreaMid();

        const dir = direction === 'down' ? 1 : -1;
        this.zone.runOutsideAngular(() => {
            this.scrollingHandler = setInterval(() => {
                const posBefore = window.scrollY;
                window.scrollBy({ top: dir * this.speed });
                if (window.scrollY == posBefore) {
                    this.stopScroll();
                }

                this.showScrollAreaTopBot();
            }, this.delay);
        });
    }

    public stopScroll(): void {
        this.hideScrollAreaMid();

        clearInterval(this.scrollingHandler);
        this.scrollingHandler = null;
    }

    public setSpeed(speed_code: 'fast' | 'slow'): void {
        if (speed_code == 'slow') {
            this.speed = this.SPEED_SLOW;
        } else if (speed_code == 'fast') {
            this.speed = this.SPEED_FAST;
        }
    }

    public showScrollAreaMid(): void {
        this.scrollAreaMid = true;
        this.cdr.detectChanges();
    }

    public hideScrollAreaMid(): void {
        this.scrollAreaMid = false;
        this.cdr.detectChanges();
    }

    public showScrollAreaTopBot(): void {
        // view is at top
        this.scrollAreaTop = window.scrollY > 0;

        // view is at bottom
        this.scrollAreaBot = document.documentElement.clientHeight + window.scrollY < document.documentElement.scrollHeight;

        this.cdr.detectChanges();
    }

    public hideScrollAreaTopBot(): void {
        this.scrollAreaTop = false;
        this.scrollAreaBot = false;
        this.cdr.detectChanges();
    }

    public hideAllScrollAreas(): void {
        this.hideScrollAreaMid();
        this.hideScrollAreaTopBot();
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PortalReport } from '@minubo-portal/modules/api/models/api-report.model';
import { ReportData, ReportSettings } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { ReportsTemplateDataChangeEvent, ReportsTemplateFormValue, ReportsTemplateViewSettingsAppliedEvent } from '../template/reports-template.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { ModelMeasure } from '@shared-lib/modules/model/mnb-model.model';

@Component({
    selector: 'reports-experience-group',
    templateUrl: './reports-experience-group.component.html'
})
export class ReportsExperienceGroupComponent implements OnInit, OnDestroy {

    private destroy$ = new Subject<void>();

    public form: FormGroup;

    public viewSettings$: BehaviorSubject<ReportSettings>;

    public report: PortalReport;
    public data: ReportData;
    public dataViewSettings: ReportSettings;

    public availableMeasures: ModelMeasure[] = [];

    ngOnInit(): void {
        const initialViewSettings = new ReportSettings();
        initialViewSettings.experienceGroup = {};
        this.viewSettings$ = new BehaviorSubject<ReportSettings>(initialViewSettings);

        this.form = new FormGroup({
            'timeFilter': new FormControl(null),
            'comparisonFilter': new FormControl(null),
            'filters': new FormControl([]),
            'selectedMeasureCodes': new FormControl([])
        });
    }

    receiveViewSettings(newViewSettings: ReportSettings) {
        const viewSettings = new ReportSettings();
        viewSettings.experienceGroup = {
            ...this.viewSettings$.getValue().experienceGroup,
            ...newViewSettings.experienceGroup,
        };
        this.viewSettings$.next(viewSettings);
    }

    onViewSettingsApplied(event: ReportsTemplateViewSettingsAppliedEvent) {
        const settings = event.viewSettings || event.report.settings;
        this.form.get('timeFilter').setValue(settings.experienceGroup.timeFilter);
        this.form.get('comparisonFilter').setValue(settings.experienceGroup.comparisonFilter);
        this.form.get('filters').setValue(settings.experienceGroup.filters || []);
        if (settings.experienceGroup.selectedMeasureCodes && settings.experienceGroup.selectedMeasureCodes.length > 0) {
            this.form.get('selectedMeasureCodes').setValue(settings.experienceGroup.selectedMeasureCodes);
        }
    }

    onViewSettingsFormValueChanged(formValue: ReportsTemplateFormValue) {
        const viewSettings = new ReportSettings();
        viewSettings.experienceGroup = {
            ...this.viewSettings$.getValue().experienceGroup,
            timeFilter: formValue.timeFilter,
            comparisonFilter: formValue.comparisonFilter,
            filters: formValue.filters,
            selectedMeasureCodes: formValue.selectedMeasureCodes,
        };
        this.viewSettings$.next(viewSettings);
    }

    onDataChanged(event: ReportsTemplateDataChangeEvent) {
        this.report = event.report;
        this.data = event.data;
        this.dataViewSettings = event.viewSettings;
    }

    onSaveSelectedMeasureCodes(selectedMeasureCodes: string[]) {
        if (selectedMeasureCodes.length > 0) {
            this.form.get('selectedMeasureCodes').setValue(selectedMeasureCodes);
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

import { NgZone } from '@angular/core';
import { ChartRenderer, DataRenderer, RendererConfig, StackedRenderer } from './chart-renderer';
import { BarChartConfig, ChartConfig, ChartData, ChartDataArray, ChartDataComparisonType, ChartDataType } from '../services/chart.models';
import { MnbUnitPipe } from '@shared-lib/modules/core/pipes/unit.pipe';
import { MnbEmptyValuePipe } from '@shared-lib/modules/core/pipes/empty-value.pipe';
import { MnbUnitService } from '@shared-lib/modules/core/services/unit/mnb-unit.service';


export abstract class ColumnBarChartRenderer extends ChartRenderer {

  protected data: Array<Array<ChartData>> = [];
  protected ele: HTMLElement;

  protected rendererConfig: RendererConfig;

  constructor(
    protected config: BarChartConfig,
    zone: NgZone,
    unitPipe: MnbUnitPipe,
    emptyPipe: MnbEmptyValuePipe,
    unitService: MnbUnitService
  ) {
    super(zone, unitPipe, emptyPipe, unitService);

    this.rendererConfig = new RendererConfig(this.config);

    if (!this.config.value.unit) {
      this.config.value.unit = 'PERCENT';
    }
  }

  public updateConfig(config: ChartConfig, updateData?: boolean) {
    super.updateConfig(config, updateData);
    this.rendererConfig = new RendererConfig(this.config);
  }

  protected createSvgElement(ele: HTMLElement): d3.Selection<SVGSVGElement, {}, null, undefined> {
    return d3.select(ele).append('svg')
      .attr('class', 'mnb-chart mnb-bar-chart')
      .attr('width', '100%')
      .attr('height', '100%');
  }

}


export class ColumnBarChartAttrHandler {
  public x: (d: ChartData | any, i?: number) => number;
  public y: (d: ChartData | any, i?: number) => number;
  public height: (d: ChartData | any) => number;
  public width: (d: ChartData | any) => number;
  public transform?: (d: ChartData | any, i?: number) => string;
  public class?: (d: ChartData) => string;
}


export class ColumnBarRenderer extends DataRenderer {

  protected className: string;

  constructor(
    private gElement: d3.Selection<SVGGElement, {}, null, undefined>,
    data: ChartDataArray,
    protected hasComparison: boolean) {

    super();

    if (data.comparisonType === ChartDataComparisonType.plan) {
      this.className = 'bar bar-plan';
    } else if (data.type === ChartDataType.primary) {
      this.className = !data.isComparison ? 'bar' : 'bar bar-comparison';
    } else if (data.type === ChartDataType.secondary) {
      this.className = !data.isComparison ? 'bar bar-secondary' : 'bar bar-secondary bar-comparison';
    }

  }

  protected renderInternal(array: ChartDataArray, handlers: ColumnBarChartAttrHandler, limit?: number, inverted?: boolean) {

    const data = array.getData(limit, inverted);

    this.gElement
      .append('g')
      .selectAll('.bar-main')
      .data(data)
      .enter().append('rect')
      .attr('class', handlers.class)
      .attr('x', handlers.x)
      .attr('y', handlers.y)
      .attr('width', handlers.width)
      .attr('height', handlers.height);
  }

  switchHighlight(index: number, highlight: boolean) {
    throw new Error('Method not implemented.');
  }
}

export class StackedColumnBarRenderer extends StackedRenderer {

  protected readonly PADDING = 8;

  constructor(private gElement: d3.Selection<SVGGElement, {}, null, undefined>, filled?: boolean) {
    super(filled);
}

  protected renderInternal(arrays: ChartDataArray[], handlers: ColumnBarChartAttrHandler, limit?: number, inverted?: boolean) {
    const keys = super.getKeys(arrays, limit, inverted);
    const series = super.createSeries(arrays, keys, limit, inverted);

    this.gElement.append('g')
      .selectAll('g')
      .data(series)
      .enter().append('g')
      .attr('class', (d, i) => 'chart-color-' + (series.length - i - 1))
      .style('fill', (d, i) => arrays.find(ele => ele.label === d.key).color)
      .attr('data-key-index', d => keys.indexOf(d.key)) // make outer index available for subgroups
      .selectAll('rect')
      .data(d => d)
      .enter().append('rect')
      .attr('x', handlers.x)
      .attr('y', handlers.y)
      .attr('height', handlers.height)
      .attr('width', handlers.width);
  }

  switchHighlight(index: number, highlight: boolean) {
    throw new Error('Method not implemented.');
  }

}

export class ParallelColumnBarRenderer extends DataRenderer {

  private breakdownData: ChartDataArray[];

  constructor(private gElement: d3.Selection<SVGGElement, {}, null, undefined>) {
    super();
  }

  /**
   * @returns breakdownSeries, grouped by breakdownkey
   */
  protected createBreakdownSeriesParallel(breakdownData: ChartDataArray[]): ChartData[][] {
    this.breakdownData = breakdownData;
    const series = breakdownData.map(breakdownSeries => breakdownSeries.getData());

    return series;
  }

  protected renderInternal(series: any[], handlers: ColumnBarChartAttrHandler) {
    this.gElement.append('g')
      .selectAll('g')
      .data(series)
      .enter().append('g')
      .attr('class', (d, i) => 'chart-color-' + i)
      .style('fill', (d, i) => this.breakdownData[i].color)
      .attr('transform', handlers.transform)
      .selectAll('rect')
      .data(d => d)
      .enter().append('rect')
      .attr('width', handlers.width)
      .attr('height', handlers.height)
      .attr('x', handlers.x)
      .attr('y', handlers.y);

  }

  switchHighlight(index: number, highlight: boolean) {

  }
}

import { Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ReportSettings } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { ModelAttribute, ModelMeasure } from '@shared-lib/modules/model/mnb-model.model';

@Component({
    selector: 'mnb-reports-table-download-data-selector',
    templateUrl: './mnb-reports-table-download-data-selector.component.html',
    styleUrls: ['./mnb-reports-table-download-data-selector.component.less']
})
export class MnbReportsTableDownloadDataSelectorComponent implements OnDestroy {

    private destroy$ = new Subject<void>();

    private download$ = new Subject<void>();

    isOpen = false;

    availableAttributes$ = new BehaviorSubject<ModelAttribute[]>([]);
    availableMeasures$ = new BehaviorSubject<ModelMeasure[]>([]);

    selectedAttributeCodes$ = new BehaviorSubject<Set<string>>(new Set());
    selectedMeasureCodes$ = new BehaviorSubject<Set<string>>(new Set());

    @Input()
    set availableDownloadAttributes(availableAttributes: ModelAttribute[]) {
        this.availableAttributes$.next(availableAttributes);
    }

    @Input()
    set availableDownloadMeasures(availableMeasures: ModelMeasure[]) {
        this.availableMeasures$.next(availableMeasures);
    }

    @Input() set viewSettings(viewSettings: ReportSettings) {
        if (!viewSettings.table.selectedDownloadAttributeCodes) {
            return;
        }

        const newCheckedAttributeCodes = new Set(viewSettings.table.selectedDownloadAttributeCodes);
        this.selectedAttributeCodes$.next(newCheckedAttributeCodes);

        const newCheckedMeasureCodes = new Set<string>(viewSettings.table.selectedDownloadMeasureCodes);
        this.selectedMeasureCodes$.next(newCheckedMeasureCodes);
    }

    @Output()
    loadAdhocFeedEmitter = new EventEmitter<LoadAdhocFeedEvent>();

    @HostBinding('class.filters-row-element') _class1 = true;
    @HostBinding('class.filters-row-elementfilters-row-time-filter') _class2 = true;

    constructor() {
        this.download$.pipe(
            takeUntil(this.destroy$),
            withLatestFrom(this.isDownloadable$)
        ).subscribe(
            ([_, isDownloadable]) => {
                if (!isDownloadable) {
                    return;
                }

                const attributeCodes = [];
                for (const attr of this.availableAttributes$.getValue()) {
                    if (this.selectedAttributeCodes$.getValue().has(attr.code)) {
                        attributeCodes.push(attr.code);
                    }
                }

                const measureCodes = [];
                for (const measure of this.availableMeasures$.getValue()) {
                    if (this.selectedMeasureCodes$.getValue().has(measure.code)) {
                        measureCodes.push(measure.code);
                    }
                }

                const loadAdhocEvent: LoadAdhocFeedEvent = {
                    attributeCodes,
                    measureCodes
                };

                this.loadAdhocFeedEmitter.emit(loadAdhocEvent);
                this.close();
            }

        );
    }

    attributeSelectorStates$: Observable<AttrMeasureSelectorState[]> = combineLatest([this.availableAttributes$, this.selectedAttributeCodes$]).pipe(
        map(([availableMeasures, newSelectedMeasureCodes]) => {
            const states: AttrMeasureSelectorState[] = [];
            for (const measure of availableMeasures) {
                const checked = newSelectedMeasureCodes.has(measure.code);
                const measureState = {
                    code: measure.code,
                    name: measure.name,
                    checked
                };
                states.push(measureState);
            }
            return states;
        })
    );

    measureSelectorStates$: Observable<AttrMeasureSelectorState[]> = combineLatest([this.availableMeasures$, this.selectedMeasureCodes$]).pipe(
        map(([availableMeasures, newSelectedMeasureCodes]) => {
            const states: AttrMeasureSelectorState[] = [];
            for (const measure of availableMeasures) {
                const checked = newSelectedMeasureCodes.has(measure.code);
                const measureState = {
                    code: measure.code,
                    name: measure.name,
                    checked
                };
                states.push(measureState);
            }
            return states;
        })
    );

    isDownloadable$ = combineLatest([this.selectedAttributeCodes$, this.selectedMeasureCodes$]).pipe(
        map(([selectedAttributeCodes, selectedMeasureCodes]) => selectedAttributeCodes.size > 0 && selectedMeasureCodes.size > 0)
    );

    onDownload() {
        this.download$.next();
    }

    toggleOpen() {
        this.isOpen = !this.isOpen;
    }

    close() {
        this.isOpen = false;
    }

    onOutsideClick() {
        this.close();
    }

    toggleCheckedAttributes(index: number) {
        const clickedAttributeCode = this.availableAttributes$.getValue()[index].code;
        const checkedMeasureCodes = this.selectedAttributeCodes$.getValue();
        if (checkedMeasureCodes.has(clickedAttributeCode)) {
            checkedMeasureCodes.delete(clickedAttributeCode);
        } else {
            checkedMeasureCodes.add(clickedAttributeCode);
        }
        this.selectedAttributeCodes$.next(checkedMeasureCodes);
    }

    toggleCheckedMeasures(index: number) {
        const clickedMeasureCode = this.availableMeasures$.getValue()[index].code;
        const selectedMeasureCodes = this.selectedMeasureCodes$.getValue();
        if (selectedMeasureCodes.has(clickedMeasureCode)) {
            selectedMeasureCodes.delete(clickedMeasureCode);
        } else {
            selectedMeasureCodes.add(clickedMeasureCode);
        }
        this.selectedMeasureCodes$.next(selectedMeasureCodes);
    }

    isEqualSets(a: Set<string>, b: Set<string>): boolean {
        return a.size === b.size && Array.from(a).every(value => b.has(value));
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}

export type LoadAdhocFeedEvent = {
    attributeCodes: string[];
    measureCodes: string[];
};

export type AttrMeasureSelectorState = {
    code: string;
    name: string;
    checked: boolean;
};

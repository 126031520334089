import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MnbConfigConfig, MnbConfigService } from './services/mnb-config.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    entryComponents: [],
    providers: [],
    exports: []
})
export class MnbConfigModule {

    public static forRoot(config: MnbConfigConfig): ModuleWithProviders<MnbConfigModule> {
        return {
            ngModule: MnbConfigModule,
            providers: [
                {provide: 'mnbConfig', useValue: config},
                MnbConfigService,
            ]
        };
    }

    public static forChild(): ModuleWithProviders<MnbConfigModule> {
        return {
            ngModule: MnbConfigModule,
            providers: []
        };
    }
}

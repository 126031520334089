import { Injectable, InjectionToken } from '@angular/core';
import { ModelEntity } from '@shared-lib/modules/model/mnb-model.model';


export const ENTITY_LINK_FACADE_SERVICE_TOKEN = new InjectionToken<EntityLinkFacadeService>('EntityLinkFacadeService');
export interface EntityLinkFacadeService {
    loadLinkEntity(attributes: {code: string}[]): Promise<ModelEntity | null>;
}

@Injectable()
export class EmptyEntityLinkFacadeService implements EntityLinkFacadeService {
    loadLinkEntity(attributes: { code: string }[]): Promise<ModelEntity | null> {
        return Promise.resolve(null);
    }
}

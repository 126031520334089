import { Component, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { MnbUnitPipe } from '@shared-lib/modules/core/pipes/unit.pipe';
import { isNullOrUndefined } from 'util';
import { ModelMeasure } from '@shared-lib/modules/model/mnb-model.model';

@Component({
    selector: 'mnb-rel-change-value',
    template: `
<span [class.muted-green]="color == 'green'" [class.muted-red]="color == 'red'">
    {{ output }}
</span>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MnbRelativeChangeValueComponent implements OnChanges {
    // either input [value + comparisonValue] or [changeValue]

    @Input() value: number;
    @Input() comparisonValue: number;

    @Input() changeValue: number;

    @Input() measure: ModelMeasure;
    @Input() absolutePercent = false;

    protected output = '';
    protected color: 'green' | 'red' | 'black' = 'black';
    private invertColor = false;

    constructor(
        private unitPipe: MnbUnitPipe,
    ) {}

    ngOnChanges(): void {
        this.color = 'black';
        this.output = '-';
        if (isNullOrUndefined(this.changeValue) && (isNullOrUndefined(this.value) || isNullOrUndefined(this.comparisonValue))) {
            return;
        }

        // assign output
        let unitCode = 'QTY';
        if (this.measure && this.measure.unit && this.measure.unit.code) {
            unitCode = this.measure.unit.code;
        }
        let mode: 'percent' | 'pp' = 'percent';
        if (this.absolutePercent && unitCode === 'PERCENT') {
            mode = 'pp';
        }

        switch (mode) {
            case 'percent':
                if (this.changeValue === undefined) {
                    this.changeValue = (this.value - this.comparisonValue) / this.comparisonValue;
                }
                this.output = this.unitPipe.transform(this.changeValue, 'PERCENT', {hasSign: true});
                break;
            case 'pp':
                if (this.changeValue === undefined) {
                    this.changeValue = this.value - this.comparisonValue;
                }
                this.output = this.unitPipe.transform(this.changeValue, 'PERCENT', {isDiff: true, hasSign: true});
                break;
        }

        // assign color
        this.invertColor = false;
        if (this.measure && this.measure.interpretation < 0) {
            this.invertColor = true;
        }

        if (this.changeValue < -0.0001) {
            this.color = this.invertColor ? 'green' : 'red';
        } else if (this.changeValue > 0.0001) {
            this.color = this.invertColor ? 'red' : 'green';
        } else {
            this.color = 'black';
        }
    }

}

import { Dashboard, DashboardWidget } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { MnbModelService } from '@shared-lib/modules/model/services/mnb-model.service';
import { TranslateService } from '@ngx-translate/core';
import { DashboardGridWidget } from '@shared-lib/modules/dashboards/util/mnb-dashboards-grid.util';
import { Injectable } from '@angular/core';
import { WidgetVisualizationType } from '@shared-lib/modules/dashboards/services/mnb-dashboard-chart.service';
import { ModelMeasure } from '@shared-lib/modules/model/mnb-model.model';

@Injectable()
export class MnbDashboardService {

    constructor(
            private translate: TranslateService,
            private modelService: MnbModelService
        ) {
    }

    public calcWidgetTitle(gridWidget: DashboardGridWidget): string {
        if (gridWidget.widget.visualizationSettings.title) {
            return gridWidget.widget.visualizationSettings.title;
        }
        if (!gridWidget.model) {
            return '';
        }

        const measures: ModelMeasure[] = gridWidget.model.measures || [];

        // Not a table
        if (!measures.length) {

            // In case there is a breakdown, title = Measure by breakdown Attribute
            const breakdownAttribute = gridWidget.model.breakdownAttribute;
            if (breakdownAttribute) {
                return gridWidget.model.measure.name + ' ' + this.translate.instant('GENERAL.MISC.BY') + ' ' + breakdownAttribute.name;
            }

            // Take the measure
            measures.push(gridWidget.model.measure);

            // Take the additional measure
            if (gridWidget.model.additionalMeasure) {
                measures.push(gridWidget.model.additionalMeasure);
            }
        }

        // if there is more than one measure then concat them otherwise take the only measure exist
        return measures.length > 1 ? this.concatMeasuresNames(measures) : (measures.length === 1 ?  measures[0].name : '');
    }

    private concatMeasuresNames(measures: ModelMeasure[]): string {
        let text = '';
        const textAnd = ' ' + this.translate.instant('GENERAL.MISC.AND') + ' ';
        const textOthers = ' ' + this.translate.instant('GENERAL.MISC.OTHERS');
        const splitter = measures.length === 2 ? textAnd : ', ';

        measures.slice(0, 2).forEach((measure) => {
            text += (text.length ? splitter : '') + measure.name;
        });

        if (measures.length > 2) {
            text += textAnd + '+' +  (measures.length - 2) + textOthers;
        }

        return text;
    }

    public calcWidgetSubtitle(gridWidget: DashboardGridWidget): string {
        if (gridWidget.widget.visualizationSettings.subtitle) {
            return gridWidget.widget.visualizationSettings.subtitle;
        } else if (DashboardWidget.isTableWidget(gridWidget.widget)) {
            return null;
        }

        return gridWidget.model.attribute ? gridWidget.model.attribute.name  : '';
    }

    checkForRealtimeMeasures(dashboard: Dashboard): Promise<boolean> {
        return Promise.all((dashboard.widgets || []).map(widget => this.checkWidgetForRealtimeMeasures(widget))).then(containsRealtime => {
            return containsRealtime.some(isRealtime => isRealtime);
        });
    }

    checkWidgetForRealtimeMeasures(widget: DashboardWidget): Promise<boolean> {
        if (widget.visualizationSettings.typeCode === WidgetVisualizationType.Text) {
            return Promise.resolve(false);
        }

        const measurePromises: Promise<ModelMeasure>[] = [];

        if (widget.querySettings.measureCode) {
            measurePromises.push(this.modelService.getMeasure(widget.querySettings.measureCode));
        }
        if (widget.querySettings.additionalMeasureCode) {
            measurePromises.push(this.modelService.getMeasure(widget.querySettings.additionalMeasureCode));
        }

        (widget.querySettings.measures || []).forEach(measure => {
            measurePromises.push(this.modelService.getMeasure(measure.code));
        });

        return Promise.all(measurePromises).then(measures => {
            return measures.some(measure => measure.modifiers.isRealtime);
        });
    }
}

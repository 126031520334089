import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ValueBarCellSettings } from '@shared-lib/modules/core/components/value-bar-cell/value-bar-cell.component';
import { DashboardWidgetData, DashboardWidgetDetail } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { DashboardWidgetDetailModel, DashboardWidgetDetailModelModel } from '@minubo-suite/analytics-area/services/dashboard/dashboard-service.models';
import { MnbComparisonPercentPipe } from '@shared-lib/modules/core/pipes/comparison-percentage.pipe';
import { MnbUnitInfo } from '@shared-lib/modules/core/pipes/unit.pipe';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { AlyDashboardWidgetDetailTableModel, AlyDashboardWidgetDetailTableRowModel, CellValueSettings, CellValueSettingsWrapper, DrilldownEvent } from '@shared-lib/modules/dashboards/components/dashboard.models';
import { ModelAttribute, ModelMeasure } from '@shared-lib/modules/model/mnb-model.model';

@Component({
    selector: 'mnb-dashboard-widget-detail-table',
    templateUrl: './mnb-dashboard-widget-detail-table.component.html'
})
export class MnbDashboardWidgetDetailTableComponent implements OnInit, OnChanges {

    @Input() public data: DashboardWidgetDetailModel;
    @Input() public detail: DashboardWidgetDetail;

    @Output() public drilldown: EventEmitter<DrilldownEvent> = new EventEmitter<DrilldownEvent>();

    public cellValueSettings: CellValueSettings;
    public hasDrilldown: boolean;

    public tableModel: AlyDashboardWidgetDetailTableModel;

    public model: DashboardWidgetDetailModelModel;

    constructor(private comparisonPercentPipe: MnbComparisonPercentPipe) { }

    ngOnInit() {
        this.hasDrilldown = this.drilldown.observers.length > 0;
        const rows = this.detail.breakdown ? this.detail.breakdown[this.data.model.attribute.code] : [];

        this.cellValueSettings = new CellValueSettings();
        this.cellValueSettings.main = this.createCellValueSettingsWrapper(rows, this.data.model.measure);


        if (this.data.model.additionalMeasure) {
            this.cellValueSettings.additional = this.createCellValueSettingsWrapper(rows, this.data.model.additionalMeasure);
        } else {
            this.data.model.assistMeasures.forEach((measure) => {
                this.cellValueSettings.assist.push(this.createCellValueSettingsWrapper(rows, measure));
            });
        }

        const dashboardCurrencyCode = QueryFilter.getCurrencyCode(this.data.dashboard.settings.filters);
        const widgetCurrencyCode = QueryFilter.getCurrencyCode(this.data.widget.querySettings.filters) || dashboardCurrencyCode;

        const breakdownCurrencyCode = (this.detail.attributes && this.detail.attributes[this.data.model.breakdownAttribute.code]) || widgetCurrencyCode;

        this.model = this.data.model;

        this.tableModel = new AlyDashboardWidgetDetailTableModel();

        const measures = [this.data.model.measure];
        if (this.data.model.additionalMeasure) {
            measures.push(this.data.model.additionalMeasure);
        } else {
            this.data.model.assistMeasures.forEach((measure) => measures.push(measure));
        }

        this.tableModel.headerRow = this.mapRow(this.detail, measures, breakdownCurrencyCode);

        this.tableModel.rows = this.detail.breakdown && this.detail.breakdown[this.data.model.attribute.code] ?
            this.detail.breakdown[this.data.model.attribute.code].map(row => this.mapRow(row, measures, breakdownCurrencyCode)) :
            [];

        const dashboardSettings = this.data.dashboard != null ? this.data.dashboard.settings : null;
        const widgetSettings = this.data.widget.querySettings != null ? this.data.widget.querySettings : null;
        const filters = widgetSettings.filters ? widgetSettings.filters : (dashboardSettings ? dashboardSettings.filters : null);
        const timeFilter = widgetSettings.timeFilter ? widgetSettings.timeFilter : (dashboardSettings ? dashboardSettings.timeFilter : null);
        const comparisonFilter = widgetSettings.timeFilter ? widgetSettings.comparisonFilter : (dashboardSettings ? dashboardSettings.comparisonFilter : null);
        this.tableModel.mergedSettings = {
            comparisonFilter: comparisonFilter,
            filters: filters,
            timeFilter: timeFilter,
            measureCode: null, // HINT: Not used here, so just filling with empty value
        };
    }

    private mapRow(row: DashboardWidgetData, measures: ModelMeasure[], widgetCurrencyCode: string) {
        const rowModel = new AlyDashboardWidgetDetailTableRowModel();
        rowModel.fromDate = row.fromDate;
        rowModel.toDate = row.toDate;
        rowModel.attributes = row.attributes;
        rowModel.values = row.values;
        rowModel.comparisonValues = null;
        rowModel.comparisonFromDate = null;
        rowModel.comparisonToDate = null;

        if (row.comparison && row.comparison.comparisonPeriod) {
            rowModel.comparisonValues = row.comparison.comparisonPeriod.values;
            rowModel.comparisonFromDate = row.comparison.comparisonPeriod.fromDate;
            rowModel.comparisonToDate = row.comparison.comparisonPeriod.toDate;
        }

        measures.forEach(measure => {
            rowModel.units[measure.code] = new MnbUnitInfo(measure.unit.code, measure.modifiers.isOriginalCurrency, 
                measure.modifiers.isOriginalCurrency ? DashboardWidgetDetail.getCurrencyCode(row) || widgetCurrencyCode : null);
        });
        return rowModel;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.model && !changes.model.firstChange) {
            this.ngOnInit();
        }
    }

    private createCellValueSettingsWrapper(rows: Array<DashboardWidgetData>, measure: ModelMeasure): CellValueSettingsWrapper {
        const mainMapper = (row: DashboardWidgetData) => row.values[measure.code];
        const comparisonDeltaMapper = (row: DashboardWidgetData) => row.values[measure.code] / row.comparison.comparisonPeriod.values[measure.code] - 1;
        const planDeltaMapper = (row: DashboardWidgetData) => row.values[measure.code] / row.values[measure.planMeasure.code] - 1;

        const wrapper = new CellValueSettingsWrapper(this.calcCellValueSettings(rows, mainMapper, this.detail.values[measure.code]));

        if (this.data.model.hasComparisonFilter) {
            wrapper.comparisonDelta = this.calcCellValueSettings(rows, comparisonDeltaMapper, this.comparisonPercentPipe.transform(this.detail.values[measure.code], this.detail.comparison.comparisonPeriod.values[measure.code]));
        }

        if (this.data.model.hasPlan && measure.planMeasure) {
            wrapper.planDelta = this.calcCellValueSettings(rows, planDeltaMapper, this.comparisonPercentPipe.transform(this.detail.values[measure.code], this.detail.values[measure.planMeasure.code]));
        }

        // if it is a delta (wrapper) settings then add negative color

        if (wrapper.comparisonDelta) {
            wrapper.comparisonDelta.useNegativeColor = true;
        }

        if (wrapper.planDelta) {
            wrapper.planDelta.useNegativeColor = true;
        }

        return wrapper;
    }

    private calcCellValueSettings(rows: Array<DashboardWidgetData>, mapper: Function, totalValue: number): ValueBarCellSettings {
        const max = rows.map((row) => Math.abs(mapper(row))).filter((value) => value !== Infinity && value !== -Infinity).reduce((a, b) => Math.max(a, b), 0);
        const hasNegative = rows.filter(row => mapper(row) < 0).length > 0;
        const hasPostive = rows.filter(row => mapper(row) >= 0).length > 0;

        const range = totalValue !== Infinity && totalValue !== -Infinity ? Math.max(max, totalValue) : max;

        return {
            range: range,
            hideNegative: !hasNegative,
            hidePositive: !hasPostive,
            fullBarVisualization: true
        };
    }

    drillDown(row: AlyDashboardWidgetDetailTableRowModel, childAttribute: ModelAttribute) {
        this.drilldown.next({row: row, attribute: childAttribute});
    }

    public isAttributeHidden(): boolean {
        return this.detail.missingModelRights && this.detail.missingModelRights.attributeCodes && this.detail.missingModelRights.attributeCodes.includes(this.data.model.attribute.code);
    }

}


import { Component } from '@angular/core';
import { PortalModalService } from '../service/portal-modal.service';

@Component({
    selector: 'mnb-view-selection-modal',
    templateUrl: './view-selection-modal.component.html',
    styleUrls: ['./view-selection-modal.component.less']
})
export class ViewSelectionModalComponent {

    constructor(
        public modalService: PortalModalService
    ) { }

}

import { Component, HostBinding, Input } from '@angular/core';
import { ModelAttribute } from '@shared-lib/modules/model/mnb-model.model';

@Component({
    selector: '[mnbModelAttributeValue]',
    host: {class: 'model-value'},
    templateUrl: './model-attribute-value.component.html'
})
export class MnbModelAttributeValueComponent {

    @Input('mnbModelAttributeValue') attribute: ModelAttribute;
    @Input() value: string;

    @HostBinding('class.model-value-hidden')
    get isHidden(): boolean {
        return this.attribute && this.attribute.isHidden;
    }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PortalModalService {

  constructor() { }

  public openModal = false;

  public exampleNames = [
      'Digital Sales Trends',
      'Urban Apparel Revenue',
      'Global Market Performance',
      'Seasonal Sales Insights Seasonal Sales Insights Seasonal Sales Insights',
      'Product Inventory Metrics',
      'Customer Engagement Overview',
      'E-commerce Revenue Breakdown',
      'Monthly Financial Summary',
      'Regional Profitability Dashboard',
      'Retail Foot Traffic Analysis',
      'Product Inventory Metrics',
      'Customer Engagement Overview',
      'E-commerce Revenue Breakdown',
      'Monthly Financial Summary',
      'Regional Profitability Dashboard',
      'Retail Foot Traffic Analysis',
  ];


  public toggleModal() {
      console.log('toggleModal');
      this.openModal = !this.openModal;
  }

  public closeModal() {
      console.log('closeModal');
      this.openModal = false;
  }
}

import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { MnbModelService } from '@shared-lib/modules/model/services/mnb-model.service';
import { FilterService } from '@shared-lib/modules/queries/services/filter-service';

@Component({
    selector: 'mnb-query-filter-infotext',
    template: `
    <p class="tile-infotext">
        <ng-container *ngIf="widgetQueryFilters">
            <span>Widget {{ 'PAGE.ALY.DASHBOARD.FILTERS.FILTERS' | translate }}: </span>
            <ng-container *ngIf="widgetFilters.length; else lineBreakElseBlock;">
                <br>
                <span *ngFor="let filter of widgetFilters">
                    {{ filter.attributeName }} {{ filter.values }}<br>
                </span><br>
            </ng-container>
            <span>{{ 'PAGE.ALY.DASHBOARD.WIDGET.INHERITED_DASHBOARD_FILTERS' | translate }}: </span>
            <ng-container *ngIf="filters.length; else elseBlock">
                <br>
                <span *ngFor="let filter of filters" [ngStyle]="{ 'text-decoration': filter.isExcluded ? 'line-through' : '' }">
                    {{ filter.attributeName }} {{ filter.values }}<br>
                </span>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!widgetQueryFilters">
            <ng-container *ngIf="queryFilters; else elseBlock;">
                <span *ngFor="let filter of filters">
                    {{ filter.attributeName }} {{ filter.values }} <br>
                </span>
            </ng-container>
        </ng-container>
        <ng-template #lineBreakElseBlock>
            <span>-</span><br><br>
        </ng-template>
        <ng-template #elseBlock>
            <span>-</span>
        </ng-template>
    </p>
    `

})
export class MnbQueryFilterInfotextComponent implements OnChanges {
    filters: Array<{ attributeName: string, typeCode: string, values: string}>;
    widgetFilters: Array<{ attributeName: string, typeCode: string, values: string, isExcluded: boolean }>;

    constructor(
        private modelService: MnbModelService,
        private filterService: FilterService,
        private elRef: ElementRef
    ) { }

    @Input() queryFilters: Array<QueryFilter>;
    @Input() widgetQueryFilters: Array<QueryFilter>;
    @Input() widgetExcludedFilters: Array<QueryFilter> = [];

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes) {
            this.loadLabels();
        }
    }
 
    private loadLabels() {
        this.filters = [];
        this.widgetFilters = [];

        const dashbaordFilters = this.queryFilters || [];

        const attributePromises = dashbaordFilters
            .concat(this.widgetQueryFilters || [])
            .map(queryFilter => this.modelService.getAttribute(queryFilter.attributeCode));

        if (attributePromises.length) {
            Promise.all(attributePromises).then(attributes => {
                if (this.widgetQueryFilters) {
                    const dashboardQueryFilterAttributes = attributes.slice(0, attributes.length + (this.widgetQueryFilters ? this.widgetQueryFilters.length : 0));
                    const widgetQueryFilterAttributes = attributes.slice(dashbaordFilters.length);
        
                    this.addFilterValuesLabels(this.filters, dashbaordFilters, dashboardQueryFilterAttributes);
                    this.addFilterValuesLabels(this.widgetFilters, this.widgetQueryFilters, widgetQueryFilterAttributes);
                } else {
                    Promise.all(attributePromises).then(dashboardQueryFilterAttributes => {
                        this.addFilterValuesLabels(this.filters, dashbaordFilters, dashboardQueryFilterAttributes);
                    });
                }
            });
        }
    }

    private addFilterValuesLabels(filterArray, queryFilters, attributes) {
        queryFilters.forEach((queryFilter: QueryFilter, i: number) => {

            let valuesLabel = this.filterService.createFilterLabel(queryFilter, this.elRef.nativeElement.parentElement);
            valuesLabel = valuesLabel.charAt(0).toLowerCase() + valuesLabel.slice(1);

            filterArray.push({
                attributeName: attributes[i].name,
                typeCode: queryFilter.typeCode,
                values: valuesLabel,
                isExcluded: this.widgetExcludedFilters ? this.widgetExcludedFilters.some(e => e.attributeCode === queryFilter.attributeCode) : false
            });
        });
    }

}

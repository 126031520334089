import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DrilldownPath, EntityDrilldownElement, Report, ReportData, ReportDrilldownTimeSeriesEntry, ReportSettings, ReportSettingsDrilldownStep, ReportSettingsEntityDrilldown } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { MnbBusyStatus } from '@shared-lib/modules/core/model/mnb-core-busy-status.model';
import { MnbModelService } from '@shared-lib/modules/model/services/mnb-model.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, take, takeUntil, tap } from 'rxjs/operators';
import { ChartRenderer } from '@shared-lib/modules/charts/renderers/chart-renderer';
import { MnbChartService } from '@shared-lib/modules/charts/services/chart.service';
import { ChartData, ChartDataArray, ChartDataComparisonType, ChartDataType, ChartValue, LineChartConfig } from '@shared-lib/modules/charts/services/chart.models';
import {
    MnbReportsEntityDrilldownDisplayDrilldownDataModel,
    MnbReportsEntityDrilldownDisplayDrilldownMeasureModel,
    MnbReportsEntityDrilldownDisplayDrilldownModel,
    MnbReportsEntityDrilldownDisplayMeasureModel,
    MnbReportsEntityDrilldownDisplayModel,
    MnbReportsEntityDrilldownDisplayUtil,
    MnbReportsEntityDrilldownRow
} from '@shared-lib/modules/reports/components/entity-drilldown/entity-drilldown.model';
import { MediaService } from '@minubo-suite/shared/services/media/media.service';
import { MnbDateSpanPipe } from '@shared-lib/modules/core/pipes/date-span.pipe';
import { TimeFilterService } from '@shared-lib/modules/core/services/time/time-filter.service';
import { TimeComparisonFilter } from '@shared-lib/modules/core/services/time/time.model';
import { QueryFilter, QuerySettingsSort } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { REPORT_DATA__SERVICE_TOKEN, ReportDataService } from '@shared-lib/modules/reports/components/report-data.service';
import deepEqual from 'deep-equal';

@Component({
    selector: 'mnb-reports-entity-drilldown-display-data',
    templateUrl: './mnb-reports-entity-drilldown-display-data.component.html',
    styleUrls: ['./mnb-reports-entity-drilldown-display-data.component.less']
})
export class MnbReportsEntityDrilldownDisplayDataComponent implements OnInit, OnDestroy {

    @Input() set report(r: Report) {
        this.report$.next(r);
    }
    @Input() set viewSettings(v: ReportSettings) {
        this.viewSettings$.next(v);
    }
    @Input() set data(d: ReportData) {
        this.data$.next(d);
    }
    @Input() set selectedMeasureCodes(selectedMeasureCodes: string[]) {
        this.selectedMeasureCodes$.next(selectedMeasureCodes);
    }

    @Output() drilldown = new EventEmitter<ReportSettingsDrilldownStep>();
    @Output() availableMeasures = new EventEmitter<MnbReportsEntityDrilldownDisplayDrilldownMeasureModel[]>();

    @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;

    private cancelHttpDrilldownData$ = new Subject<void>();
    private cancelHttpTimeSeriesData$ = new Subject<void>();

    private data$ = new BehaviorSubject<ReportData>(null);
    private viewSettings$ = new BehaviorSubject<ReportSettings>(null);
    private report$ = new BehaviorSubject<Report>(null);
    private selectedMeasureCodes$ = new BehaviorSubject<string[]>([]);

    public chartLoad = new MnbBusyStatus();

    public model$ = new Subject<MnbReportsEntityDrilldownDisplayModel>();

    public readonly activeMeasureTile$ = new BehaviorSubject<{ measureTileIdx: number, code: string }>({ measureTileIdx: 0, code: null });

    public chartRenderer: ChartRenderer;

    private timeSeriesData$ = new BehaviorSubject<{ settings: ReportSettingsEntityDrilldown, entries: ReportDrilldownTimeSeriesEntry[] }>(null);

    private destroy$ = new Subject<void>();

    constructor(
        private modelService: MnbModelService,
        private mediaService: MediaService,
        @Inject(REPORT_DATA__SERVICE_TOKEN) private reportsService: ReportDataService,
        private chartService: MnbChartService,
        private timeFilterService: TimeFilterService,
        private timeDateSpanPipe: MnbDateSpanPipe,
    ) {
    }

    async ngOnInit(): Promise<void> {

        combineLatest([this.mediaService.viewSize$, this.model$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([_, model]) => {
                this.adjustMeasureDisplay(model);
                model.drilldowns.forEach(drilldownModel => {
                    this.adjustDrilldownMeasureDisplay(drilldownModel);
                });
            });

        combineLatest([this.report$, this.viewSettings$, this.data$])
            .pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe(([report, viewSettings, data]) => this.loadModel(report, viewSettings, data));

        // Properly setting initial activeMeasureTile value
        combineLatest([this.model$, this.activeMeasureTile$]).pipe(
            filter(([model, activeMeasureTile]) => model != null && model.measures.length > 0 && activeMeasureTile.code == null),
            take(1),
        ).subscribe(([model, activeMeasureTile]) => {
            const code = model.measures[activeMeasureTile.measureTileIdx].measure.code;
            this.activeMeasureTile$.next({ measureTileIdx: activeMeasureTile.measureTileIdx, code });
        });


        this.initMeasureChartSubscription();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private initMeasureChartSubscription(): void {
        const initialConfig = new LineChartConfig();
        initialConfig.data = [new ChartDataArray(ChartDataType.primary, '', [])];
        initialConfig.value = new ChartValue();
        this.chartRenderer = this.chartService.createLineChartRenderer(initialConfig);

        combineLatest([this.activeMeasureTile$, this.report$, this.viewSettings$]).pipe(
            filter(([activeMeasureTile, _, __]) => !!activeMeasureTile  && !!activeMeasureTile.code),
            debounceTime(100), // Debounce loading a bit to not have too many requests to load histogram data
            distinctUntilChanged(([tileA, _reportA, _viewSettingsA], [tileB, _reportB, _viewSettingsB]) => {
                return tileA.code === tileB.code && deepEqual(_viewSettingsA, _viewSettingsB);
            }),
            takeUntil(this.destroy$)
        ).subscribe(([activeMeasureTile, report, viewSettings]) => {
            void this.loadTimeSeriesData(report, viewSettings, activeMeasureTile.code);
        });

        // Update chart after we get new histogram data
        this.timeSeriesData$.subscribe(async data => {
            if (!data || !data.entries || !data.entries.length) {
                return;
            }

            const chartConfig = new LineChartConfig();
            const measure = await this.modelService.getMeasure(Object.keys(data.entries[0].values)[0]);

            const dateSpan = this.timeFilterService.getTimePeriod(data.settings.timeFilter);

            const chartData = data.entries.map(d => {
                const dataLabel = d.timeAttribute;
                const value = Object.values(d.values)[0];
                return new ChartData(dataLabel, value, null, 'blue');
            });

            const label = this.timeDateSpanPipe.transform(dateSpan, { shorten: true });
            chartConfig.data = [
                new ChartDataArray(ChartDataType.primary, label, chartData)
            ];

            const hasComparison = data.entries.some(d => d.compareValues != null && Object.values(d.compareValues).length > 0);
            if (hasComparison) {
                const comparisonDateSpan = this.timeFilterService.getComparisonPeriod(data.settings.timeFilter, <TimeComparisonFilter>data.settings.comparisonFilter);
                const comparisonLabel = this.timeDateSpanPipe.transform(comparisonDateSpan, { shorten: true });

                const comparisonData = data.entries
                    .map(d => {
                        if (!d.compareValues) {
                            return null;
                        }
                        const dataLabel = d.timeAttribute;
                        const value = Object.values(d.compareValues)[0];
                        return new ChartData(dataLabel, value, null, 'blue');
                    })
                    .filter(d => d != null);
                chartConfig.data.push(new ChartDataArray(ChartDataType.primary, comparisonLabel, comparisonData, ChartDataComparisonType.comparison));

            } else {
                chartConfig.legendConfig = { hide: true };
            }

            chartConfig.value = new ChartValue();
            if (data.entries.length > 0) {
                chartConfig.value.unit = measure.unit.code;
                chartConfig.value.code = measure.code;
                chartConfig.value.label = label;
            }


            // Debounce chartRender update. Only execute once its container is visible.
            if (this.chartContainer && this.chartContainer.nativeElement) {
                this.chartRenderer.updateConfig(chartConfig, true);
                this.chartRenderer.rerender();
            } else {
                // If the chart container is not available yet wait for it
                const checkInterval = setInterval(() => {
                    if (this.chartContainer && this.chartContainer.nativeElement) {
                        clearInterval(checkInterval);
                        this.chartRenderer.updateConfig(chartConfig, true);
                        this.chartRenderer.rerender();
                    }
                }, 300);
                // Clear the interval after 20 seconds max
                setTimeout(() => clearInterval(checkInterval), 20000);
            }
        });
    }


    private findDeepestMatchingPathElement(path: DrilldownPath, drilldownFilters: QueryFilter[]): EntityDrilldownElement {

        for (let pathIdx = path.path.length - 1; pathIdx >= 0; pathIdx--) {
            const elem = path.path[pathIdx];
            if (elem.conditions && elem.conditions.length && elem.conditions.length > 0) {
                for (const condition of elem.conditions) {
                    for (const _filter of drilldownFilters) {
                        const conditionMatchesFilter = _filter.attributeCode === condition.attributeCode && _filter.typeCode === condition.typeCode;
                        const conditionHasValues = condition.values != null && condition.values.length && condition.values.length > 0;
                        const conditionMatchesFilterValue = conditionHasValues
                            ? condition.values.some(val => _filter.values.includes(val))
                            : true;

                        if (conditionMatchesFilter && conditionMatchesFilterValue) {
                            return elem;
                        }
                    }
                }
            } else if (pathIdx > 0) { // elem lacks conditions and is not the first element -->  Check if ts previous element is part of the filter
                // Check for a matching filter based on the previous element's attributeCode.
                const prevElem = path.path[pathIdx - 1];
                for (const _filter of drilldownFilters) {
                    if (prevElem.keyAttributes.map(attr => attr.code).includes(_filter.attributeCode)) {
                        return elem; // Return the current element if a matching filter is found.
                    }
                }
            }
        }
        // Fallback scenario
        return path.path[0];
    }

    /*
    NOTE: Maybe we can do this cleaner. Not a fan of having to calculate this on frontend and backend.
    Shouldn't be part of the payload either to avoid conflicts
     */
    findMeasureSetIndex(settings: ReportSettings, drilldownFilters: QueryFilter[]): number {
        const measureSets = settings.entityDrilldown.measureSets;
        for (let setIdx = measureSets.length - 1; setIdx >= 0; setIdx--) {
            const currSet = measureSets[setIdx];
            if (!!currSet.conditions && currSet.conditions.length > 0) {
                for (const condition of currSet.conditions) {
                    for (const _filter of drilldownFilters) {
                        const conditionMatchesFilter = _filter.attributeCode === condition.attributeCode && _filter.typeCode === condition.typeCode;
                        const conditionMatchesFilterValue = condition.values.some(val => _filter.values.includes(val));
                        if (conditionMatchesFilter && conditionMatchesFilterValue) {
                            return setIdx;
                        }
                    }
                }
            }
        }
        return 0;
    }
    private async loadModel(report: Report, viewSettings: ReportSettings, data: ReportData) {
        if (!data || !report.settings.entityDrilldown.drilldownPathList) {
            return;
        }

        this.cancelHttpDrilldownData$.next();

        const settings = viewSettings || report.settings;
        const drilldownFilters = settings.entityDrilldown.selectedDrilldownFilters;
        const isDrilldownSelected = drilldownFilters != null && drilldownFilters.length && drilldownFilters.length > 0;
        const measureIdx = isDrilldownSelected ? this.findMeasureSetIndex(report.settings, drilldownFilters) : 0;
        const availableMeasures = report.settings.entityDrilldown.measureSets[measureIdx].measures || [];
        const selectedMeasureCodes = settings.entityDrilldown.selectedMeasureCodes;

        const drilldownData: MnbReportsEntityDrilldownDisplayDrilldownModel[] =
            (await Promise.all((settings.entityDrilldown.drilldownPathList || [])
                .map(async (path, i) => {
                    let pathElement: EntityDrilldownElement = path.path[0]; // Default is first entry
                    if (isDrilldownSelected) {
                        pathElement = this.findDeepestMatchingPathElement(path, drilldownFilters);
                    }

                    const alreadyFilteredOn = !!drilldownFilters && drilldownFilters
                        .map(f => f.attributeCode)
                        .some(code => pathElement.keyAttributes.map(attr => attr.code).includes(code));
                    if (alreadyFilteredOn) {
                        return null;
                    }

                    const selectedPath = path.pathIdentifier;

                    let keyAttributes = await Promise.all(pathElement.keyAttributes.map(a => this.modelService.getAttribute(a.code)));
                    let nameAttributes = await Promise.all((pathElement.nameAttributes || []).map(a => this.modelService.getAttribute(a.code)));
                    if (!nameAttributes.length) {
                        nameAttributes = keyAttributes;
                        keyAttributes = [];
                    }

                    const sort = pathElement.drilldownSort;
                    const availableDrilldownMeasures: MnbReportsEntityDrilldownDisplayDrilldownMeasureModel[] = (await Promise.all((availableMeasures)
                        .map(m => this.modelService.getMeasure(m.code)))).map(measure => {
                        return {
                            measure,
                            hidden: false,
                            sortDirection: sort && sort.measureCode === measure.code ? sort.directionCode : null
                        };
                    });

                    if (availableDrilldownMeasures.length > 0) {
                        this.availableMeasures.emit(availableDrilldownMeasures);
                    }

                    const selectedDrilldownMeasures = availableDrilldownMeasures.filter(val => selectedMeasureCodes.includes(val.measure.code));

                    const drilldownModel: MnbReportsEntityDrilldownDisplayDrilldownModel = {
                        hasMoreMeasures: false,
                        showsFirstMeasure: false,
                        showsLastMeasure: false,
                        measures: selectedDrilldownMeasures,
                        keyAttributes: keyAttributes,
                        nameAttributes: nameAttributes,
                        name: pathElement.elementName,
                        pathRef: selectedPath,
                        load: new MnbBusyStatus(),
                        expanded: false,
                        sort$: new Subject<QuerySettingsSort>(),
                        data$: new BehaviorSubject<MnbReportsEntityDrilldownDisplayDrilldownDataModel>(null)
                    };
                    const loadSettings = { ...viewSettings };
                    loadSettings.entityDrilldown.selectedElementName = drilldownModel.name;
                    loadSettings.entityDrilldown.selectedPathIdentifier = drilldownModel.pathRef;
                    loadSettings.entityDrilldown.selectedMeasureCodes = selectedMeasureCodes;
                    this.loadDrilldownData(drilldownModel, loadSettings, report);

                    drilldownModel.sort$.subscribe(newSort => {
                        drilldownModel.load.reset();
                        drilldownModel.data$.next(null);
                        drilldownModel.measures.forEach(measureModel => {
                            measureModel.sortDirection = newSort && newSort.measureCode === measureModel.measure.code ? newSort.directionCode : null;
                        });

                        // Mutating sort
                        loadSettings.entityDrilldown.drilldownPathList.forEach(_path => {
                            if (_path.pathIdentifier === drilldownModel.pathRef) {
                                _path.path.forEach(pathElem => {
                                    if (pathElem.elementName === drilldownModel.name) {
                                        pathElem.drilldownSort = newSort;
                                        loadSettings.entityDrilldown.selectedElementName = pathElement.elementName;
                                        loadSettings.entityDrilldown.selectedPathIdentifier = _path.pathIdentifier;
                                    }
                                });
                            }
                        });
                        this.loadDrilldownData(drilldownModel, loadSettings, report);
                    });

                    return drilldownModel;
                }))).filter(d => d !== null); // Removing null-values we explicitly returned

        const viewData = data.entityDrilldown.entity;

        const measures: MnbReportsEntityDrilldownDisplayMeasureModel[] = await Promise.all(selectedMeasureCodes.map(async measureCode => {
                const measure = await this.modelService.getMeasure(measureCode);
                const value = viewData && viewData.values ? viewData.values[measureCode] : 0;
                const comparisonValue = viewData && viewData.compareValues ? viewData.compareValues[measureCode] : null;
                let hasNegativeComparison = false;
                if (!!viewSettings.entityDrilldown.comparisonFilter) {
                    if (measure.interpretation > 0) {
                        hasNegativeComparison = value < comparisonValue;
                    } else if (measure.interpretation < 0) {
                        hasNegativeComparison = value > comparisonValue;
                    }
                }
                return {
                    measure,
                    value,
                    comparisonValue,
                    hasNegativeComparison
                };
            }));

        const model: MnbReportsEntityDrilldownDisplayModel = {
            drilldowns: drilldownData,
            hasComparison: !!settings.entityDrilldown.comparisonFilter,
            hasMoreMeasures: false,
            measures: measures,
            showsFirstMeasure: false,
            showsLastMeasure: false,
        };
        this.adjustMeasureDisplay(model);

        this.model$.next(model);
    }

    public onDrilldown(rowModel: MnbReportsEntityDrilldownRow, drilldownModel: MnbReportsEntityDrilldownDisplayDrilldownModel) {

        const linkModel = rowModel.link;
        const nameValues: { [code: string]: string } = {};

        if (drilldownModel.keyAttributes && drilldownModel.keyAttributes.length > 0) {
            // Only set this if keyAttributes contains any values, otherwise nameValues and keyValues might contain the same data
            for (const value of drilldownModel.nameAttributes) {
                nameValues[value.code] = rowModel.data.attributes[value.code];
            }
        }

        const step = new ReportSettingsDrilldownStep();
        step.keyValues = linkModel.keyValues;
        step.nameValues = nameValues;

        this.drilldown.emit(step);
    }

    private loadDrilldownData(
        model: MnbReportsEntityDrilldownDisplayDrilldownModel,
        viewSettings: ReportSettings,
        report: Report
    ) {
        const drilldownSettings = new ReportSettingsEntityDrilldown();


        drilldownSettings.timeFilter = viewSettings ? viewSettings.entityDrilldown.timeFilter : report.settings.entityDrilldown.timeFilter;
        drilldownSettings.comparisonFilter = viewSettings ? viewSettings.entityDrilldown.comparisonFilter : report.settings.entityDrilldown.comparisonFilter;
        drilldownSettings.filters = viewSettings ? viewSettings.entityDrilldown.filters : null;
        drilldownSettings.drilldownPathList = viewSettings.entityDrilldown.drilldownPathList;

        drilldownSettings.selectedDrilldownFilters = viewSettings ? viewSettings.entityDrilldown.selectedDrilldownFilters : [];
        drilldownSettings.selectedPathIdentifier = viewSettings ? viewSettings.entityDrilldown.selectedPathIdentifier : null;
        drilldownSettings.selectedElementName = viewSettings ? viewSettings.entityDrilldown.selectedElementName : null;
        drilldownSettings.selectedMeasureCodes = viewSettings ? viewSettings.entityDrilldown.selectedMeasureCodes : [];

        this.reportsService.getData(report.id, { entityDrilldown: drilldownSettings })
            .pipe(
                takeUntil(this.cancelHttpDrilldownData$),
            )
            .subscribe(data => {
                if (data.entityDrilldown) {
                    model.data$.next({
                        rows: data.entityDrilldown.drilldownRows.map(drilldownRow => {
                            return {
                                link: MnbReportsEntityDrilldownDisplayUtil.buildEntityLink(drilldownSettings, drilldownRow),
                                data: drilldownRow
                            };
                        })
                    });
                } else {
                    model.data$.next({ restricted: true });
                }
                model.load.done();
            });
    }

    private async loadTimeSeriesData(report: Report, viewSettings: ReportSettings, measureTileCode: string): Promise<void> {
        this.cancelHttpTimeSeriesData$.next();

        const drilldownSettings = new ReportSettingsEntityDrilldown();

        drilldownSettings.timeFilter = viewSettings ? viewSettings.entityDrilldown.timeFilter : report.settings.entityDrilldown.timeFilter;
        drilldownSettings.comparisonFilter = viewSettings ? viewSettings.entityDrilldown.comparisonFilter : report.settings.entityDrilldown.comparisonFilter;
        drilldownSettings.filters = viewSettings ? viewSettings.entityDrilldown.filters : null;
        drilldownSettings.timeSeriesMeasureCode = measureTileCode;
        drilldownSettings.drilldownPathList = viewSettings ? viewSettings.entityDrilldown.drilldownPathList : [];

        drilldownSettings.selectedDrilldownFilters = viewSettings ? viewSettings.entityDrilldown.selectedDrilldownFilters : [];

        this.chartLoad.reset();
        this.reportsService.getData(report.id, { entityDrilldown: drilldownSettings }).pipe(
            tap({
                next: () => this.chartLoad.done(),
                error: (err) => this.chartLoad.httpError(err),
            }),
            takeUntil(this.cancelHttpTimeSeriesData$),
        )
            .subscribe(result => this.timeSeriesData$.next({
                settings: drilldownSettings,
                entries: result.entityDrilldown.timeSeries
            }));
    }

    private adjustMeasureDisplay(model: MnbReportsEntityDrilldownDisplayModel, dir?: -1 | 1) {
        const width = this.mediaService.viewSize$.getValue().width;
        const previousStart = model.measures.findIndex(m => !m.hidden) + (dir || 0);
        let availableCount = Math.max(1, Math.floor(width / 260));
        availableCount = Math.min(availableCount, 4);
        const diff = (previousStart + availableCount) - model.measures.length;

        const start = diff > 0 ? Math.max(previousStart - diff, 0) : previousStart;
        const end = start + availableCount;

        model.measures.forEach((measureModel, i) => {
            measureModel.hidden = i < start || i >= end;
        });
        model.hasMoreMeasures = availableCount < model.measures.length;
        model.showsFirstMeasure = start === 0;
        model.showsLastMeasure = end === model.measures.length;

        if (this.activeMeasureTile$.value && start > this.activeMeasureTile$.value.measureTileIdx) {
            const code = model.measures[start].measure.code;
            this.onMeasureTileClick(start, code);
        } else if (this.activeMeasureTile$.value && end - 1 < this.activeMeasureTile$.value.measureTileIdx) {
            const code = model.measures[start].measure.code;
            this.onMeasureTileClick(end - 1, code);
        }
    }

    onMeasureMove(model: MnbReportsEntityDrilldownDisplayModel, dir: -1 | 1) {
        this.adjustMeasureDisplay(model, dir);
    }

    private adjustDrilldownMeasureDisplay(drilldownModel: MnbReportsEntityDrilldownDisplayDrilldownModel, dir?: -1 | 1) {
        const previousStart = drilldownModel.measures.findIndex(m => !m.hidden) + (dir || 0);
        // const availableCount = Math.max(1, Math.floor((width - 260) / 130));
        const availableCount = 5000;
        const diff = (previousStart + availableCount) - drilldownModel.measures.length;

        const start = diff > 0 ? Math.max(previousStart - diff, 0) : previousStart;
        const end = start + availableCount;
        drilldownModel.measures.forEach((measureModel, i) => {
            measureModel.hidden = i < start || i >= end;
            measureModel.isLastVisible = i === end - 1;
        });
        drilldownModel.hasMoreMeasures = availableCount < drilldownModel.measures.length;
        drilldownModel.showsFirstMeasure = start === 0;
        drilldownModel.showsLastMeasure = end === drilldownModel.measures.length;
    }

    onDrilldownMeasureMove(drilldownModel: MnbReportsEntityDrilldownDisplayDrilldownModel, dir: -1 | 1) {
        this.adjustDrilldownMeasureDisplay(drilldownModel, dir);
    }

    onMeasureTileClick(tileIndex: number, measureCode: string) {
        this.activeMeasureTile$.next({ measureTileIdx: Math.max(tileIndex, 0), code: measureCode });
    }

    onDrilldownSortClick(drilldownModel: MnbReportsEntityDrilldownDisplayDrilldownModel, measureModel: MnbReportsEntityDrilldownDisplayDrilldownMeasureModel) {
        const directionCode = !measureModel.sortDirection ? 'DESC' : measureModel.sortDirection === 'ASC' ? null : 'ASC';
        drilldownModel.sort$.next({ measureCode: measureModel.sortDirection === 'ASC' ? null : measureModel.measure.code, directionCode });
    }
}

import { ActivatedRoute, ActivatedRouteSnapshot, NavigationExtras, Router } from '@angular/router';
import { Location } from '@angular/common';

const settingsParam = 'settings';
/**
 * Checks the route for a filter parameter. If given, tries to deserialize it to JSON
 * @param route to check for `filter` parameter
 * @return deserialized filter parameter value if in json format, null if deserialization fails or parameter doesn't exit
 */
export function tryParseFilters<T>(route?: ActivatedRouteSnapshot): (Partial<T> | null) {
    if (!route || Object.keys(route.queryParams).indexOf(settingsParam) < 0) {
        // no filters set
        return null;
    }
    let filters = null;
    try {
        const decoded = decodeURIComponent(route.queryParams[settingsParam]);
        filters = JSON.parse(decoded) as Partial<T>;
    } catch (e) {
        // Likely not interesting for us. If the URL parsing fails, we ignore the parameters
    }
    return filters;
}
/**
 * replacing the filter param with either the updated state, or removing it if settings are `null`
 * @param settings will be serialized using `encodeURIComponent`
 * @param location referenced location object that should be updated
 * @param router referenced router to create the url tree
 * @param route referenced route for url creation
 */
export function updateRouteWithFilters<T extends object>(settings: T | null, location: Location, router: Router, route: ActivatedRoute): void {
    const param: NavigationExtras = {
        relativeTo: route,
    };

    if (settings != null) {
        param.queryParams = { [settingsParam]: JSON.stringify(settings) };
    }

    location.replaceState(
        router.createUrlTree([], param).toString()
    );
}

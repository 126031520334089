import { Directive, ElementRef, Inject, Input, Optional } from '@angular/core';
import { ANGULAR_STATE } from '@minubo-suite/shared/modules/legacy/services/legacy-router-service.provider';

@Directive({
  selector: '[uiSref]'
})
export class UiSrefDirective {

  @Input('uiSref') route: string;
  @Input('uiSrefParams') params : any;


  constructor(private el: ElementRef, @Optional() @Inject(ANGULAR_STATE) private $state: any) {}
  
  ngOnInit() {
    this.el.nativeElement.href = this.$state.href(this.route, this.params, null);
  }

}
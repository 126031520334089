import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@shared-lib/modules/core/services/language/language.service';


/*
 * Returns a 'emtpy' text instead of nothing if an value is empty

 * Usage:
 *   value | mnbEmptyValue
 * Example:
 *   {{ null | mnbEmptyValue }}
 *   formats to: (empty)
*/
@Pipe({ name: 'mnbEmptyValue' })
export class MnbEmptyValuePipe implements PipeTransform {

    constructor(private languageService: LanguageService) {

    }

    transform(input: string, config?: any): string {
        // config.replaceEmptyString can be used to only replace if input is empty string
        if (config && config.replaceEmptyString && input !== '') {
            return input;
        }
        if (typeof input === 'undefined' || input === null || input === '') {
            return this.languageService.getEmptyLabel();
        }
        return input;
    }
}

import { MnbUnitInfo } from '@shared-lib/modules/core/pipes/unit.pipe';
import { ValueBarCellSettings } from '@shared-lib/modules/core/components/value-bar-cell/value-bar-cell.component';
import { DashboardWidgetQuerySettings } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { ModelAttribute } from '@shared-lib/modules/model/mnb-model.model';

export class AlyDashboardWidgetDetailTableModel {
    headerRow: AlyDashboardWidgetDetailTableRowModel;

    rows: AlyDashboardWidgetDetailTableRowModel[];
    mergedSettings: DashboardWidgetQuerySettings;
}

export class AlyDashboardWidgetDetailTableRowModel {
    attributes?: { [attributeCode: string]: string };
    values?: { [measureCode: string]: number };
    comparisonValues?: { [measureCode: string]: number };

    units: { [measureCode: string]: MnbUnitInfo } = {};

    fromDate: number | Date;
    toDate: number | Date;
    comparisonFromDate: number | Date;
    comparisonToDate: number | Date;
}

export class CellValueSettings {

    public main: CellValueSettingsWrapper;
    public additional: CellValueSettingsWrapper;
    public assist: Array<CellValueSettingsWrapper>;

    constructor() {
        this.assist = [];
    }
}

export class CellValueSettingsWrapper {
    constructor(public value: ValueBarCellSettings, public comparisonDelta?: ValueBarCellSettings, public planDelta?: ValueBarCellSettings) {
    }
}

export type DrilldownEvent = {
    row: DrilldownRow;
    attribute: ModelAttribute
};

export type DrilldownRow = {
    attributes?: { [attributeCode: string]: string };
    values?: { [measureCode: string]: number };
    comparisonValues?: { [measureCode: string]: number };

    fromDate: number | Date;
    toDate: number | Date;
    comparisonFromDate: number | Date;
    comparisonToDate: number | Date;
};

import { MnbChartTooltipData } from '@shared-lib/modules/charts/components/chart-tooltip-content.component';
import { MnbUnitInfo } from '@shared-lib/modules/core/pipes/unit.pipe';
import { DashboardWidgetQuerySettings, DashboardWidgetVisualizationSettingsMeasure } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { ModelAttribute, ModelMeasure } from '@shared-lib/modules/model/mnb-model.model';

export class TableAttribute {
    public showBorderLeft: boolean;
    constructor(public attribute: ModelAttribute, i: number, public width: number) {
        this.showBorderLeft = (i > 0);
    }
}

export class TableMeasure {
    public colSpan: number;
    public displayHeader: boolean;
    public showValueBorderLeft: boolean;
    public showComparisonBorderLeft: boolean;
    public showChangeBorderLeft: boolean;
    public showChangeSmall: boolean;
    public showPlanValueBorderLeft: boolean;
    public showPlanDeviationBorderLeft: boolean;
    public showPlanDeviationSmall: boolean;

    public width: number;

    constructor(public measure: ModelMeasure, i: number, public settings: DashboardWidgetVisualizationSettingsMeasure, hasComparisonFilter: boolean, hasPlan: boolean) {
        if (settings) {
            this.colSpan = (settings.showValue ? 1 : 0)
                + (settings.showComparisonValue && hasComparisonFilter ? 1 : 0)
                + (settings.showChange && hasComparisonFilter ? 2 : 0)
                + (settings.showPlanValue && hasPlan ? 1 : 0)
                + (settings.showPlanDeviation && hasPlan ? 2 : 0);

            this.width = Math.max(this.colSpan, 3) / 2;

            if (i > 0) {
                let hasBorder = false;
                this.showValueBorderLeft = hasBorder = settings.showValue;
                if (!hasBorder) {
                    this.showComparisonBorderLeft = hasBorder = settings.showComparisonValue;
                }
                if (!hasBorder) {
                    this.showChangeBorderLeft = hasBorder = settings.showChange;
                } else {
                    this.showChangeSmall = true;
                }

                if (!hasBorder) {
                    this.showPlanValueBorderLeft = hasBorder = settings.showPlanValue;
                }

                if (!hasBorder) {
                    this.showPlanDeviationBorderLeft = true;
                } else {
                    this.showPlanDeviationSmall = true;
                }
            }
        } else {
            this.colSpan = 1;
            this.showValueBorderLeft = (i > 0);
        }
        this.displayHeader = settings &&
            (settings.showValue
                || ((settings.showChange || settings.showComparisonValue) && hasComparisonFilter)
                || ((settings.showPlanDeviation || settings.showPlanValue) && hasPlan));
    }
}

export class DashboardWidgetTableModel {
    public attributes: Array<TableAttribute>;
    public measures: Array<TableMeasure>;
    public childAttributes: Array<ModelAttribute>;
    public breakdownAttribute?: ModelAttribute;


    public colCount: number;
    public colWeightCount: number;

    public totalWidth: number;

    public headerRow: DashboardWidgetTableRowModel;

    public rows: DashboardWidgetTableRowModel[];
    mergedSettings: DashboardWidgetQuerySettings;

    constructor(
        public hasComparisonFilter: boolean,
        public hasPlan: boolean,
        width: number,
        attributes: Array<ModelAttribute>,
        measures: Array<ModelMeasure>,
        measureSettings: Array<DashboardWidgetVisualizationSettingsMeasure>,
        childAttributes: Array<ModelAttribute>,
    ) {

        this.measures = measures.map((measure: ModelMeasure, index: number) => {
            const settings = measureSettings.length > index ? measureSettings[index] : new DashboardWidgetVisualizationSettingsMeasure();
            return new TableMeasure(measure, index + attributes.length, settings, hasComparisonFilter, hasPlan);
        });

        const measuresWidth = this.measures.map(measure => measure.width).reduce((a, b) => a + b, 0);

        const attributeWidth = Math.min(3, (width - measuresWidth) / attributes.length);

        this.attributes = attributes.map((a, i) => new TableAttribute(a, i, attributeWidth));

        this.colCount = this.attributes.length + this.measures.map(m => m.colSpan).reduce((a, b) => a + b, 0);
        this.totalWidth = measuresWidth + (attributeWidth * attributes.length);
        
        if (childAttributes != null) {
            this.childAttributes = [...childAttributes];
        } else {
            this.childAttributes = [];
        }
    }
}


export class DashboardWidgetTableRowModel {
    attributes?: {[attributeCode: string]: string};
    values?: {[measureCode: string]: number};
    comparisonValues?: {[measureCode: string]: number};

    units: {[measureCode: string]: MnbUnitInfo} = {};

    tooltipData: { [measureCode: string]: MnbChartTooltipData } = {};
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewSelectionModalComponent } from './view-selection-modal/view-selection-modal.component';
import { PortalModalService } from './service/portal-modal.service';
import { MnbCoreModule } from '@shared-lib/modules/core/mnb-core.module';

@NgModule({
    declarations: [
        ViewSelectionModalComponent
    ],
    imports: [
        CommonModule,
        MnbCoreModule,
    ],
    exports: [
        ViewSelectionModalComponent
    ],
    providers: [
        PortalModalService
    ]
})
export class PortalModalModule { }

import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { ChartRenderer } from '../renderers/chart-renderer';
import { MnbChartTooltipContentComponent } from './chart-tooltip-content.component';
import { MediaService } from '../../../../../../minubo-suite/src/app/shared/services/media/media.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'mnb-chart',
    template: '<div #container></div><div mnbTooltipOwner appendToBody="true"><mnb-tooltip-content><mnb-chart-tooltip-content #tooltipContent></mnb-chart-tooltip-content></mnb-tooltip-content></div>'
})

export class MnbChartComponent implements OnDestroy, AfterViewInit {

    @Input() renderer: ChartRenderer;
    @Input() height: number;

    @ViewChild('container', { static: true }) container: any;
    @ViewChild('tooltipContent', { static: true }) tooltipContent: MnbChartTooltipContentComponent;

    private resizeSubscription: Subscription;

    constructor(private mediaService: MediaService) { }

    ngAfterViewInit() {
        if (!this.renderer) {
            return;
        }

        const element: HTMLElement = this.container.nativeElement;
        // watch container for removed elements, if the svg is removed(chart is rerender/destroyed) remove all tooltips
        new MutationObserver(e => this.mutationObserverHandler(e)).observe(element, { childList: true, subtree: true });

        this.renderer.el = this.container.nativeElement;
        this.renderer.tooltipContent = this.tooltipContent;
        this.renderer.specifiedHeight = this.height;

        this.renderer.rerender();

        this.resizeSubscription = this.mediaService.onViewSizeChanged.subscribe(() => {
            this.renderer.rerender();
        });
    }

    ngOnDestroy() {
        this.removeChartTooltips();

        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
        }
    }

    private mutationObserverHandler(e) {
        if (e[0].removedNodes) {
            this.removeChartTooltips();
        }
    }

    private removeChartTooltips() {
        // removes ALL mnb-chart-tooltips from the DOM
        Array.prototype.slice.call(document.getElementsByTagName('mnb-chart-tooltip')).forEach(tooltipEle => tooltipEle.remove());
    }

}

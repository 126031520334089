import { ModelMeasure } from '@shared-lib/modules/model/mnb-model.model';

export class MnbComparisonUtil {

    static calcValue(value: number, comparisonValue: number): number {
        return value - comparisonValue;
    }

    static calcPercentage(value: number, comparisonValue: number): number {
        return ((value - comparisonValue) / comparisonValue);
    }

    static calcComparison(value: number, comparisonValue: number, measure: ModelMeasure): MnbComparison {
        const comparison = new MnbComparison();
        comparison.percentage = MnbComparisonUtil.calcPercentage(value, comparisonValue);
        comparison.isPositive = (comparison.percentage >= 0 && measure.interpretation >= 0) || (measure.interpretation < 0 && comparison.percentage < 0);
        comparison.isHigher = comparison.percentage >= 0;

        if (isNaN(comparison.percentage)) {
            comparison.percentage = null;
        }

        return comparison;
    }
}

export class MnbComparison {

    percentage: number;
    isPositive: boolean;
    isHigher: boolean;
}

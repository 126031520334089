import { Directive, ElementRef, HostBinding, HostListener, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[textAreaHandler]'
})
export class MnbTextAreaHandlerDirective implements OnInit {

    @Input('textAreaHandler') config: { maxHeight?: number } = {};
    @HostBinding('class.input-textarea') styleClass = true;
    @HostBinding('rows') rows = 1;

    private element: HTMLTextAreaElement;
    private lastValue: string;

    constructor(_elementRef: ElementRef) {
        this.element = _elementRef.nativeElement;
    }

    @HostListener('ngModelChange') adjust(): void {
        if (this.lastValue === this.element.value) {
            return;
        }
        this.element.style.height = null;
        const height = this.element.scrollHeight + 1;
        this.element.style.height = height + 'px';
        if (height > this.config.maxHeight) {
            if (this.element.style.overflow !== 'auto') {
                this.element.style.overflow = 'auto';
            }
            this.element.scrollTo({ top: this.element.scrollHeight });
        } else if (this.element.style.overflow !== 'hidden') {
            this.element.style.overflow = 'hidden';
        }
        this.lastValue = this.element.value;
    }

    ngOnInit(): void {
        this.element.style.maxHeight = this.config.maxHeight + 'px';
        this.element.style.overflow = 'hidden';
        setTimeout(() => {
            this.element.value = this.element.value.trim();
            this.adjust();
        });
    }
}

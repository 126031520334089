import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { SessionService } from "../../services/session.service";
import { MnbSession } from "../../services/session.model";
import { FeatureRightCode } from "../../modules/session/model/feature-right.model";

@Directive({
    selector: '[featureRightsRequired]'
})
export class MnbFeatureRightsRequiredDirective implements OnInit {

    private session: MnbSession;

    @Input('featureRightsRequired') featureRightsRequired: FeatureRightCode[];

    constructor(
        private elementRef: ElementRef,
        sessionService: SessionService
    ) {
        this.session = sessionService.getSession();
    }

    ngOnInit() {
        if (this.featureRightsRequired.some(featureRight => {
            return this.session.featureRights.restrictedFeatures.includes(featureRight);
        })) {
            this.elementRef.nativeElement.style.display = 'none';
        }
    }
}
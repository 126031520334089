import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, EventEmitter, NgZone, OnDestroy, Output, Renderer2, ViewRef } from '@angular/core';

@Directive({
    selector: '[mnbClickOutside]'
})
export class MnbClickOutsideDirective implements AfterViewInit, OnDestroy {
    private mouseDownListener: Function;
    private mouseUpListener: Function;

    private startedInside: boolean;

    @Output()
    public mnbClickOutside = new EventEmitter<MouseEvent>();

    constructor(
        private readonly zone: NgZone,
        private readonly renderer: Renderer2,
        private elementRef: ElementRef,
        private ref: ChangeDetectorRef
    ) {}

    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            this.mouseDownListener = this.renderer.listen('document', 'mousedown', (event) => {
                this.onMouseDown(event);
            });
            this.mouseUpListener = this.renderer.listen('document', 'mouseup', (event) => {
                this.onMouseUp(event);
            });
        });
    }

    ngOnDestroy() {
        // Moved into if-clause because this throws an exception on phones, as there's no mouse events to listen to
        if (this.mouseDownListener) {
            this.mouseDownListener();
            this.mouseUpListener();
        }
      }

    public onMouseDown(event: MouseEvent): void {
        this.startedInside = event.target && this.elementRef.nativeElement.contains(event.target);
    }

    public onMouseUp(event: MouseEvent): void {
        const endedInside = event.target && this.elementRef.nativeElement.contains(event.target);
        if (!this.startedInside && !endedInside) {
            // click started and ended outside
            this.mnbClickOutside.emit(event);
            if (!(this.ref as ViewRef).destroyed) {
                this.ref.detectChanges();
            }
        }
        this.startedInside = false;
    }
}

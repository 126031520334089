import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'delete-double-confirm',
  templateUrl: './delete-double-confirm.component.html',
})
export class MnbDeleteDoubleConfirmComponent implements OnInit {

  @Input()
  public showDeleteDialog: boolean;

  @Output()
  public delete = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  protected requestConfirmation() {
    this.showDeleteDialog = true;
  }

  protected confirmDelete() {
    this.delete.emit(this);
  }

  protected cancel() {
    this.showDeleteDialog = false;
  }

}

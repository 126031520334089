import { QueryFilter, QuerySettingsComparisonFilter, QuerySettingsTimeFilter } from './mnb-data-query.model';
import { ModelEntity } from '@shared-lib/modules/model/mnb-model.model';

export class ModelEntityLink {
    entity: ModelEntity;
    attributes: {[code: string]: string};
    timeFilter: QuerySettingsTimeFilter;
    comparisonFilter: QuerySettingsComparisonFilter;

    public static getEntitiesForAttributesAndData(entities: ModelEntity[], attributeCodes: string[], data: {code: string}[], filters?: QueryFilter[]): { [attributeCode: string]: ModelEntity} {

        const dataEntities = ModelEntityLink.getEntitiesForData(entities, data, filters);

        const attributeEntities: {[code: string]: ModelEntity} = {};

        attributeCodes.forEach(attributeCode => {

            // get the one with the most attributes
            // hint: this is not really optimal. but we dont have a better solution yet (and with the limited amount of test entities its ok)
            let minCount = 1000000;
            let entity: ModelEntity = null;

            dataEntities
                .filter(e => e.keyAttributes[e.keyAttributes.length - 1].code === attributeCode)
                .forEach(e => {
                    if (e.keyAttributes.length < minCount) {
                        entity = e;
                        minCount = e.keyAttributes.length;
                    }
                });

            Object.keys(attributeEntities).forEach(otherCode => {
                if (attributeEntities[otherCode] === entity) {
                    attributeEntities[otherCode] = null;
                }
            });

            attributeEntities[attributeCode] = entity;
        });

        return attributeEntities;
    }

    public static getEntitiesForData(entities: ModelEntity[], data: {code: string}[], filters?: QueryFilter[]): ModelEntity[] {
        return entities.filter(entity => {
            return entity.keyAttributes.every(ka => {
                if (data.find(a => a.code === ka.code)) {
                    return true;
                }
                return QueryFilter.hasValueForAttributeInFilters(ka.code, filters);
            });
        });
    }

    public static create(entity: ModelEntity, attributes: {[code: string]: string}, timeFilter?: QuerySettingsTimeFilter, comparisonFilter?: QuerySettingsComparisonFilter, filters?: QueryFilter[]): ModelEntityLink {
        if (entity == null) {
            return null;
        }

        let hasValue = false;

        const linkAttributes: {[code: string]: string} = {};

        entity.keyAttributes.forEach(ka => {
            linkAttributes[ka.code] = QueryFilter.getValueForAttributeFromFilters(ka.code, filters) || attributes[ka.code];

            hasValue = hasValue || !!linkAttributes[ka.code];
        });

        if (hasValue) {
            const link = new ModelEntityLink();
            link.entity = entity;
            link.attributes = linkAttributes;
            link.timeFilter = timeFilter;
            link.comparisonFilter = comparisonFilter;

            return link;
        } else {
            return null;
        }
    }
}

export enum MnbChannelTypeCode {
    STORE = 'store',
    WEB = 'web',
    MARKETPLACE = 'marketplace',
    MOBILE = 'mobile',
    WHOLESALE = 'wholesale'
}

export interface MnbChannelType {

    code: MnbChannelTypeCode;
    label: string;
}

export class MnbChannelTypes {

    static ALL: MnbChannelType[] = [{
        code: MnbChannelTypeCode.STORE,
        label: 'GENERAL.MODEL.CHANNEL_TYPE.STORE'
    }, {
        code: MnbChannelTypeCode.WEB,
        label: 'GENERAL.MODEL.CHANNEL_TYPE.WEB'
    }, {
        code: MnbChannelTypeCode.MARKETPLACE,
        label: 'GENERAL.MODEL.CHANNEL_TYPE.MARKETPLACE'
    }, {
        code: MnbChannelTypeCode.MOBILE,
        label: 'GENERAL.MODEL.CHANNEL_TYPE.MOBILE'
    }, {
        code: MnbChannelTypeCode.WHOLESALE,
        label: 'GENERAL.MODEL.CHANNEL_TYPE.WHOLESALE'
    }];

    static getByCode(code: MnbChannelTypeCode): MnbChannelType {
        return MnbChannelTypes.ALL.find((m) => m.code === code);
    }
}

export enum MnbMarketplaceCode {
    AMAZON = 'amazon',
    EBAY = 'ebay',
    OTTO = 'otto',
    ZALANADO = 'zalando',
    ABOUT_YOU = 'aboutYou',
    GALAXUS = 'galaxus',
    KAUFLAND = 'kaufland'
}

export interface MnbMarketplace {

    code: MnbMarketplaceCode;
    label: string;
}

export class MnbMarketplaces {

    static ALL: MnbMarketplace[] = [{
        code: MnbMarketplaceCode.AMAZON,
        label: 'GENERAL.MODEL.MARKETPLACE.AMAZON'
    }, {
        code: MnbMarketplaceCode.EBAY,
        label: 'GENERAL.MODEL.MARKETPLACE.EBAY'
    }, {
        code: MnbMarketplaceCode.OTTO,
        label: 'GENERAL.MODEL.MARKETPLACE.OTTO'
    }, {
        code: MnbMarketplaceCode.ZALANADO,
        label: 'GENERAL.MODEL.MARKETPLACE.ZALANADO'
    }, {
        code: MnbMarketplaceCode.ABOUT_YOU,
        label: 'GENERAL.MODEL.MARKETPLACE.ABOUT_YOU'
    }, {
        code: MnbMarketplaceCode.GALAXUS,
        label: 'GENERAL.MODEL.MARKETPLACE.GALAXUS'
    }, {
        code: MnbMarketplaceCode.KAUFLAND,
        label: 'GENERAL.MODEL.MARKETPLACE.KAUFLAND'
    }];

    static getByCode(code: MnbMarketplaceCode): MnbMarketplace {
        return MnbMarketplaces.ALL.find((m) => m.code === code);
    }
}
import * as Sentry from '@sentry/angular';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { instrumentAngularRouting, User } from '@sentry/angular';

export type SentryInitOpts = {
    dsn: string;
    stage?: string;
    release?: string;
};

export type SentryUserOpts = {
    id?: number;
    tenantId?: number;
    partnerId?: number;
    portalId?: number;
};

export const initSentry = (opts: SentryInitOpts) => {
    Sentry.init({
        dsn: opts.dsn,
        autoSessionTracking: true,
        environment: opts.stage || 'unknown',
        release: opts.release || 'unknown',
        integrations: [
            new TracingIntegrations.BrowserTracing({
                tracingOrigins: ['*'],
                routingInstrumentation: instrumentAngularRouting,
            }),
        ],

        tracesSampleRate: 0.4
    });
};


export const initSentryUserContext = (opts: SentryUserOpts) => {
    const data: Partial<User> = {};
    Object.keys(opts).forEach(key => {
        if (opts[key] !== undefined) {
            data[key] = String(opts[key]);
        }
    });
    data.email = 'scrubbed'; // We don't actually want to send the email for now
    Sentry.setUser(data);
};

import { Subject } from 'rxjs';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { ReportSettings, ReportSettingsTable } from '@shared-lib/modules/data/model/mnb-data-reports.model';


export class MnbReportsTableDrilldownState {
    public reloadData$ = new Subject<void>();
    public viewSettings$ = new Subject<ReportSettings>();
    public stack: { attribute?: { code: string }, value?: string }[] = [];
    public activeDrilldownFilters: QueryFilter[];
    public defaultAttributes: Array<{ code: string }> = [];
    public availableAttributes: Array<{ code: string }> = [];
    public currentAttributeCode: string;

    constructor(protected settings: ReportSettingsTable) {
        if (settings) {
            this.settings = settings;
            this.setDefaultSettings(settings);
        }
    }

    public setDefaultSettings(settings: ReportSettingsTable) {
        if (this.defaultAttributes.length === 0) {
            this.settings = settings;
            this.defaultAttributes = deepCopy(settings.attributes);
            this.update();
        }
    }

    public setSettings(settings: ReportSettingsTable) {
        this.settings = settings;
        this.update();
    }

    public down(event: { attribute?: { code: string }, value?: string }) {
        if (this.stack.length === 0) {
            this.stack.push({ attribute: this.settings.attributes[0], value: event.value });
        } else {
            this.stack.push({ attribute: this.settings.drilldownAttributes[this.stack.length - 1], value: event.value });
        }
        this.update();
        this.emitSettings();
        this.reloadData$.next();
    }

    public up(drillUpIndex: number) {
        this.stack = this.stack.filter((e, i) => i < drillUpIndex);
        this.update();
        this.emitSettings();
        this.reloadData$.next();
    }

    public update() {
        this.updateAvailableAttributes();
        this.updateDrilldownFilters();
    }

    private updateAvailableAttributes() {
        if (this.settings.drilldownAttributes && this.settings.drilldownAttributes.length > this.stack.length) {
            this.availableAttributes = [deepCopy(this.settings.drilldownAttributes[this.stack.length])];
        } else {
            this.availableAttributes = [];
        }

        if (this.stack.length > 0) {
            this.currentAttributeCode = this.settings.drilldownAttributes[this.stack.length - 1].code;
        } else {
            this.currentAttributeCode = this.defaultAttributes[0].code;
        }
    }

    private updateDrilldownFilters() {
        // derive filters from selected drilldown path
        this.activeDrilldownFilters = [];

        this.stack.forEach(stackItem => {
            const queryFilter = new QueryFilter(stackItem.attribute.code, 'equals', [stackItem.value]);
            this.activeDrilldownFilters.push(queryFilter);
        });
    }

    emitSettings() {
        const viewSettings = new ReportSettings();
        viewSettings.table = {
            attributes: [{code: this.currentAttributeCode}],
            activeDrilldownFilters: this.activeDrilldownFilters
        };
        this.viewSettings$.next(viewSettings);
    }

}

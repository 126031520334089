import { Component, Input } from '@angular/core';
import { MnbTooltipOwnerDirective } from '../../core/components/tooltip/tooltip-owner.directive';
import { TooltipConfig } from '../services/chart.models';
import { MnbUnitInfo } from '../../core/pipes/unit.pipe';


@Component({
    selector: 'mnb-chart-tooltip-content',
    templateUrl: './chart-tooltip-content.component.html',
})
export class MnbChartTooltipContentComponent {

    @Input() tooltipData: MnbChartTooltipData;
    @Input() customTooltip: { index: number,  config: TooltipConfig };

    constructor(public tooltipOwner: MnbTooltipOwnerDirective) {}

}

export class MnbChartTooltipData {

    withSecondHeaderLabel: boolean
    withShares: boolean;
    withComparison: boolean;
    plan: string;
    //TODO: remove unit code once all tooltips aree switch to unitinfo
    values: {unitCode: string, unit?: MnbUnitInfo};
    secondaryValues?: {unitCode: string, unit?: MnbUnitInfo};

    headerData: {label: string, label2?: string, value?: number, sum?: number, comparisonSum?: number};
    data: {label: string, value: number, comparisonValue?: number, planValue?: number};
    secondaryData?: {label: string, value: number, comparisonValue?: number, planValue?: number};

    breakdownData: Array<{label: string, value: number, unit?: MnbUnitInfo}>;

    rows?: { headerTitle: string, values: { label: string, value: number | string, unit?: MnbUnitInfo, type: 'text' | 'number' }[] }
}
import { Directive, ElementRef, HostBinding, HostListener, Input, OnInit, Optional, Renderer2 } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { MnbForm } from '../../../../../../../shared-lib/src/lib/modules/core/model/mnb-form.model';

/**
 * @deprecated use form-save-button component instead
 */
@Directive({
    selector: '[mnbFormSaveAction]'
})
export class MnbFormSaveActionDirective implements OnInit {
    @Input('mnbFormSaveAction') saveAction: Function;
    @Input() form: FormGroup;
    private mnbForm: MnbForm;

    @HostBinding('class.is-disabled')
    @Input('blocked') blocked: boolean;
    private isSubmitting = false;

    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef,
        @Optional() private formDirective: FormGroupDirective) {
    }

    ngOnInit(): void {
        if (!this.form && this.formDirective) {
            this.form = this.formDirective.form;
        }
        if (this.form instanceof MnbForm) {
            this.mnbForm = this.form;
        }

        this.form.statusChanges.subscribe(() => {
            if (this.form.valid) {
                this.renderer.removeClass(this.elementRef.nativeElement, 'has-form-errors');
            }
        });
        this.elementRef.nativeElement.addEventListener('click', this.act.bind(this));
    }


    @HostListener('window:keyup.enter', ['$event'])
    onEnterKey() {
        if (document.activeElement.tagName !== 'INPUT' && document.activeElement.tagName !== 'TEXTAREA') {
            this.act();
        }
    }

    public save() {
        this.act();
    }

    private act(): void {
        if (this.blocked) {
            return;
        }
        if (!this.form.valid) {
            MnbForm.markFormAsDirtyAndUnread(this.form);
            if (this.mnbForm) {
                this.mnbForm.refreshErrorState();
            }
            this.renderer.addClass(this.elementRef.nativeElement, 'has-form-errors');
        } else if (!this.isSubmitting) {
            this.isSubmitting = true;
            this.renderer.addClass(this.elementRef.nativeElement, 'is-busy');
            const p: Promise<any> = this.saveAction();
            if (p && p.then) {
                p.then(() => {
                    this.afterSubmitted();
                }, (error) => {
                    this.afterSubmitted(true);
                });
            }
        }
    }



    afterSubmitted(error?: boolean) {
        this.renderer.removeClass(this.elementRef.nativeElement, 'is-busy');
        if (!error) {
            this.renderer.addClass(this.elementRef.nativeElement, 'is-saved');
        } else {
            this.renderer.addClass(this.elementRef.nativeElement, 'has-error');
        }

        // form.$setPristine();

        this.isSubmitting = false;

        setTimeout(() => {
            this.renderer.removeClass(this.elementRef.nativeElement, 'is-saved');
            this.renderer.removeClass(this.elementRef.nativeElement, 'has-error');
        }, 3000);
    }

}

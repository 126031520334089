import { NgModule } from '@angular/core';

import { CommonModule, DatePipe } from '@angular/common';

import { MnbBusyIndicatorComponent, MnbBusyIndicatorDirective } from './directives/busy-indicator/mnb-busy-indicator.directive';
import { MnbDropdownMenuComponent } from './components/dropdown-menu/mnb-dropdown-menu.component';
import { MnbDropdownMenuButtonComponent } from './components/dropdown-menu/mnb-dropdown-menu-button.component';
import { MnbDropdownMenuListComponent } from './components/dropdown-menu/mnb-dropdown-menu-list.component';
import { MnbDropdownMenuItemComponent } from './components/dropdown-menu/mnb-dropdown-menu-item.component';
import { MnbUnitService } from './services/unit/mnb-unit.service';
import { MnbDurationPipe } from './pipes/duration.pipe';
import { MnbUnitPipe } from './pipes/unit.pipe';
import { MnbEmptyValuePipe } from './pipes/empty-value.pipe';
import { MnbTooltipContentComponent, MnbTooltipOwnerDirective } from './components/tooltip/tooltip-owner.directive';
import { NgVarDirective } from './directives/ng-var/ng-var.directive';
import { MnbDatePipe } from './pipes/mnb-date.pipe';
import { MnbTooltipDirective } from './components/tooltip/tooltip.directive';
import { MnbTimeDatePipe } from './pipes/time-date.pipe';
import { MnbDateUTCPipe } from './pipes/mnb-date-utc.pipe';
import { MnbDateSpanPipe } from './pipes/date-span.pipe';
import { MnbComparisonAbsolutePipe } from './pipes/comparison-absolute.pipe';
import { MnbComparisonPercentPipe } from './pipes/comparison-percentage.pipe';
import { MnbColorPipe } from './pipes/color.pipe';
import { MnbTileDropdownContentComponent, MnbTileDropdownOwnerDirective } from './components/tile-dropdown/tile-dropdown-owner.directive';
import { MnbTimePipe } from './pipes/time.pipe';
import { MnbTimeTenantPipe } from './pipes/time-tenant.pipe';
import { MnbValueBarCellComponent } from './components/value-bar-cell/value-bar-cell.component';
import { MnbDateSpanPickerComponent } from './components/date-span-picker/mnb-date-span-picker.component';
import { TranslateModule } from '@ngx-translate/core';
import { MnbFormGroupErrorsComponent } from './components/form-group-errors/mnb-form-group-errors.component';
import { MnbFormGroupValidationErrorsComponent } from './components/form-group-validation-errors/mnb-form-group-validation-errors.component';
import { MnbFormGroupValidationComponent } from './components/form-group-validation/mnb-form-group-validation.component';
import { MnbScrollingHandlerComponent } from './components/scrolling-handler/mnb-scrolling-handler.component';
import { MnbFormGroupComponent } from './components/form-group/mnb-form-group.component';
import { MnbFormSectionDirective } from './directives/form-section/mnb-form-section.directive';
import { MnbDropdownInputComponent } from './components/dropdown-input/mnb-dropdown-input.component';
import { MnbListActionChildDirective } from './directives/list-action-handler/mnb-list-action-child.directive';
import { MnbListActionHandlerDirective } from './directives/list-action-handler/mnb-list-action-handler.directive';
import { MnbSwitchToggleComponent } from './components/switch-toggle/mnb-switch-toggle.component';
import { MnbClickOutsideDirective } from './directives/click-outside/mnb-click-outside.directive';
import { DateInputMaskDirective } from './directives/date-input/mnb-date-input.directive';
import { MnbLoadingSpinnerComponent, MnbLoadingSpinnerDirective } from './directives/loading-spinner/mnb-loading-spinner.directive';
import { MnbComparisonDisplayPipe } from './pipes/comparison-display.pipe';
import { MnbAutosizedValueComponent } from './components/autosized-value/mnb-autosized-value.component';
import { MnbEnumerationPipe } from '@shared-lib/modules/core/pipes/enumeration.pipe';
import { MnbChannelTypeNamePipe } from '@shared-lib/modules/core/pipes/mnb-channel-type-name.pipe';
import { MnbRelativeChangeValueComponent } from './components/change-value/mnb-relative-change-value.component';
import { MnbAbsoluteChangeValueComponent } from './components/change-value/mnb-absolute-change-value.component';

@NgModule({
    declarations: [
        MnbBusyIndicatorComponent,
        MnbDropdownMenuComponent,
        MnbDropdownMenuButtonComponent,
        MnbDropdownMenuListComponent,
        MnbDropdownMenuItemComponent,
        MnbTileDropdownContentComponent,
        MnbValueBarCellComponent,
        MnbDateSpanPickerComponent,
        MnbFormGroupErrorsComponent,
        MnbFormGroupValidationErrorsComponent,
        MnbFormGroupValidationComponent,
        MnbScrollingHandlerComponent,
        MnbFormGroupComponent,
        MnbDropdownInputComponent,
        MnbSwitchToggleComponent,
        MnbLoadingSpinnerComponent,
        MnbAutosizedValueComponent,
        MnbRelativeChangeValueComponent,
        MnbAbsoluteChangeValueComponent,

        MnbBusyIndicatorDirective,
        MnbTooltipDirective,
        MnbTooltipOwnerDirective,
        MnbTooltipContentComponent,
        MnbTileDropdownOwnerDirective,
        MnbFormSectionDirective,
        MnbListActionChildDirective,
        MnbListActionHandlerDirective,
        MnbClickOutsideDirective,
        DateInputMaskDirective,
        MnbLoadingSpinnerDirective,
        NgVarDirective,

        MnbDurationPipe,
        MnbUnitPipe,
        MnbEmptyValuePipe,
        MnbDatePipe,
        MnbTimeDatePipe,
        MnbDateUTCPipe,
        MnbDateSpanPipe,
        MnbComparisonAbsolutePipe,
        MnbComparisonPercentPipe,
        MnbComparisonDisplayPipe,
        MnbColorPipe,
        MnbTimePipe,
        MnbTimeTenantPipe,
        MnbEnumerationPipe,
        MnbChannelTypeNamePipe,

    ],
    entryComponents: [
        MnbBusyIndicatorComponent,
        MnbLoadingSpinnerComponent,
        MnbTileDropdownContentComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
    ],
    exports: [
        MnbBusyIndicatorComponent,
        MnbDropdownMenuComponent,
        MnbDropdownMenuButtonComponent,
        MnbDropdownMenuListComponent,
        MnbDropdownMenuItemComponent,
        MnbTileDropdownContentComponent,
        MnbValueBarCellComponent,
        MnbDateSpanPickerComponent,
        MnbFormGroupErrorsComponent,
        MnbFormGroupValidationErrorsComponent,
        MnbFormGroupValidationComponent,
        MnbScrollingHandlerComponent,
        MnbFormGroupComponent,
        MnbDropdownInputComponent,
        MnbSwitchToggleComponent,
        MnbLoadingSpinnerComponent,
        MnbAutosizedValueComponent,
        MnbRelativeChangeValueComponent,
        MnbAbsoluteChangeValueComponent,

        MnbBusyIndicatorDirective,
        MnbTooltipDirective,
        MnbTooltipOwnerDirective,
        MnbTooltipContentComponent,
        MnbTileDropdownOwnerDirective,
        MnbFormSectionDirective,
        MnbListActionChildDirective,
        MnbListActionHandlerDirective,
        MnbClickOutsideDirective,
        DateInputMaskDirective,
        MnbLoadingSpinnerDirective,
        NgVarDirective,

        MnbDurationPipe,
        MnbUnitPipe,
        MnbEmptyValuePipe,
        MnbDatePipe,
        MnbTimeDatePipe,
        MnbDateUTCPipe,
        MnbDateSpanPipe,
        MnbComparisonAbsolutePipe,
        MnbComparisonPercentPipe,
        MnbComparisonDisplayPipe,
        MnbColorPipe,
        MnbTimePipe,
        MnbTimeTenantPipe,
        MnbEnumerationPipe,
        MnbChannelTypeNamePipe,

    ],
    providers: [
        MnbUnitService,
        DatePipe,
        MnbDurationPipe,
        MnbUnitPipe,
        MnbEmptyValuePipe,
        MnbDatePipe,
        MnbTimeDatePipe,
        MnbDateUTCPipe,
        MnbDateSpanPipe,
        MnbComparisonAbsolutePipe,
        MnbComparisonPercentPipe,
        MnbComparisonDisplayPipe,
        MnbColorPipe,
        MnbTimePipe,
        MnbTimeTenantPipe,
        MnbEnumerationPipe,
        MnbChannelTypeNamePipe,
    ]
})
export class MnbCoreModule {

}
